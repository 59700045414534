import { notification } from 'antd';
import { ReactComponent as X_ICON } from '../../media/images/x_icon.svg';
import './styles.scss';
import { SUCCESS_NOTIFICATION } from '../../settings/constants';

export const showNotification = (title, type, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export const showNotificationInfo = (title, type, message) => {
  notification.open({
    description: message,
    closeIcon: <X_ICON className="close-icon-notification-item" />,
    className: 'close-icon-notification',
    style: {
      background: type === SUCCESS_NOTIFICATION ? '#006ebb' : '#E54C51',
      borderRadius: 8,
      color: 'white',
      fontSize: 16,
      paddingRight: '2rem',
    },
  });
};

export const showNotificationNoClosable = (title, type, message, handleClose = () => {}) => {
  notification.open({
    description: message,
    message: title,
    onClose: () => handleClose(),
    closeIcon: <X_ICON className="close-icon-notification-item" />,
    className: 'close-icon-notification-approval',
    style: {
      background: type === SUCCESS_NOTIFICATION ? '#006ebb' : '#E54C51',
      borderRadius: 8,
      color: 'white',
      fontSize: 16,
      paddingRight: '2rem',
      fontFamily: 'Avenir Medium',
    },
  });
};
