import React, { Component, useEffect, useState } from 'react';
import { useNavigate, withRouter } from 'react-router-dom';
import { getAuthTokenName, isNull } from '../../utils/tools';
import Apollo from '../Apollo';
import routesDictionary from '../../routes/routesDict';

const baseStates = {
  loading: () => null,
  component: (props) => <Apollo {...props} />,
};

function Base() {
  const [globalState, setGlobalState] = useState(true);
  const [render, setRender] = useState('loading');
  const [isLogin, setIsLogin] = useState('');
  const accessToken = localStorage.getItem(getAuthTokenName());
  const navigate = useNavigate();

  useEffect(() => {
    if(!isNull(accessToken)){
      setIsLogin(accessToken);
    }
    setRender('component');
  }, [accessToken]);

  const setLoginState = (newState, postFunction) => {
    if (!newState) {
      navigate(routesDictionary.login);
    }
    setIsLogin(newState);
    if( typeof postFunction === 'function'){
      postFunction();
    }
  };

  const componentProps = {
    globalState,
    isLogin,
    setLoginState
  };
  return baseStates[render](componentProps);
}

export default Base;
