import { gql } from 'apollo-boost';
import { RequestDealFields, RequestFileFields, RequestIOIFields } from '../../fragments';

/**
 * Query to get request speed match
 */
export const GET_REQUEST_SPEED_MATCH = gql`
 query ($id: ID!) {
    getRequest(id: $id) {
      id
      owner {
        id
        firstName
         client {
           clientType
           category {
             id
             name
           }
         }
      }
      requestType
      status
      dealName
      creationDate
      operation
      productType
      volumeStart
      volumeEnd
      volumeRangeOpen
      volume
      volumeOpen
      isin
      isinOpen
      issuerType{
        id
        name
        description
      }
      issuerNameOpen
      debtType {
        id
        name
        active
        tokenized
      }
      productCategory {
        id
        name
        selectable
      }
      productRatingOpen
      maturityRangeOpen
      maturity
      maturityOpen
      cleanPriceStart
      cleanPriceEnd
      cleanPriceRangeOpen
      cleanPriceOpen
      couponRangeOpen
      coupon
      couponOpen
      couponConventionOpen
      spreadRangeOpen
      spread
      spreadOpen
      benchmark {
        id
        name
      }
      benchmarkOpen
      yieldValueStart
      yieldValueEnd
      yieldValueRangeOpen
      yieldValue
      yieldValueOpen
      description
      descriptionOpen
      documentsOpen
      notes
      internalId
      settlementDate
      issuerName
      issuerCountry {
        id
        name
        code
        weight
      }
      currency {
        id
        name
        code
        sign
        signPosition
      }
      maturityStart
      maturityEnd
      spreadStart
      spreadEnd
      couponStart
      couponEnd
      cleanPrice
      couponConvention {
        id
        name
        active
      }
      issuerNameVisible
      sponsorNameVisible
      borrowerNameVisible
      investorNameVisible
      issuerSector {
        id
        name
        description
      }
      investorName
      investorType {
        id
        name
      }
      investorCountry {
        id
        name
        code
        weight
      }
      issuerCreditQualityRating {
        id
        name: display
        parent {
          id
          name: display
        }
      }
      annualSales
      leverageRatio
      netDebt
      totalDebt
      totalInvestmentCosts
      financingAmountStart
      financingAmountEnd
      typeDebtInstrument{
        id
        name
        active
      }
      repayment{
        id
        name
      }
      couponFeature{
        id
        name
        parent {
          id
          name
        }
      }
      couponFrequency {
        id
      }
      couponDaycount{
        id
        name
      }
      deadline
      additionalInformation
      blacklistedCounterpartyCategories{
        id
        name
        hidden
      }
      blacklistedCounterparties{
        id
        companyName
        street
        houseNumber
        state
      }
      sponsorName
      sponsorType{
        id
        name
      }
      sponsorCountry{
        id
        name
        code
        weight
      }
      assetClass{
        id
        name
      }
      location{
        id
        name
      }
      country{
        id
        name
        code
        weight
      }
      address
      numberObjects
      lettableSpace
      rentalUnits
      numberTenants
      occupancyRate
      grossRentalIncomeActual
      grossRentalIncomeErv
      netOperatingIncome
      wault
      buildingCondition{
        id
        name
      }
      yearConstruction
      tenure{
        id
        name
      }
      financingPurpose{
        id
        name
      }
      financingRank{
        id
        name
      }
      loanTermStart
      loanTermEnd
      marketValueEstimate
      ltc
      ltv
      debtYield
      typeLoan{
        id
        name
      }
      collateral{
        id
        name
      }
      amortization
      makewhole
      upfrontFee
      exitFee
      borrowerName
      borrowerSector{
        id
        name
      }
      borrowerCountry{
        id
        name
        code
        weight
      }
      fixedInterestPeriodStart
      fixedInterestPeriodEnd
      issuerSector {
        id
      }
      investorPresentation {
        id
        name
        fileName
        link
      }
      documentation {
        id
        name
        fileName
        link
      }
      annualReport {
        id
        name
        fileName
        link
      }
      other {
        id
        name
        fileName
        link
      }
      
      # Loandland files
      inSpeedMatchProcess
      draftTermSheet
      headsOfTerms
      activatedTermSheet
      creditApproval
      facilityAgreement
      settlementDate
      settlementDateLoanland
      refinanceExistingDebt
      refinanceExistingShareholderLoan
      notary
      netPurchasePrice
      realStateTransferTax
      capex
      otherTransactionCosts
      totalSources
      financingAmount
      equityVolume
      totalInvestmentCostsLoanland
    
      projectDescription {
        ...RequestFileFields
      }
      rentRoll {
        ...RequestFileFields
      }
      propertyInformation {
        ...RequestFileFields
      }
      cashFlow {
        ...RequestFileFields
      }
      kycChecklist {
        ...RequestFileFields
      }
    }
  }
  ${RequestFileFields}
`;

/**
 * Mutation to get ioi fields
 */
export const GET_IOI_SPEED_MATCH = gql`
  query ($id: ID!) {
    getIoi(id: $id) {
      ...RequestIOIFields
      request {
        ...RequestDealFields
      }
      pendingNegotiations {
        id
        field
        fieldCamelCase
        value
        lastValue
        isOwnNegotiation
      }
       
      unreadAcceptedNegotiations {  
        id
        field
        fieldCamelCase
        value
        lastValue
        isOwnNegotiation
        status
      }
      
      unreadDeclinedNegotiations {  
        id
        field
        fieldCamelCase
        value
        lastValue
        isOwnNegotiation
        status
      }
    }
  }
  ${RequestIOIFields}
  ${RequestDealFields}
 `;

/**
 * Query to get only ioi fields needed to check speedmatch process
 */
export const GET_IOI_ONLY_SPEED_MATCH = gql`
  query ($id: ID!) {
    getIoi(id: $id) {
       id
       requestOwner {
        id
        client {
          id
          clientType
        }
      }
       speedMatchStatus
       isMatchRequestOwner
       owner {
         id
         firstName
         client {
           clientType
         }
       }
       matchsummary {
        id
        summary
        history
        chat
       }
       chatroom {
          id
          ioi {
            id
            request {
              id
            }
          }
          counterparty {
            id
          }
          counterpartyActiveChat
        }
       status
       
       # Files
        annualReport {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        investorPresentation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        documentation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        other {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        
        projectDescription {        
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        rentRoll {        
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        propertyInformation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        cashFlow {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        kycChecklist {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        
        termsheetsignedSet {
          id
          fileName
          link
          termSheetType
        }
        
        request {
          ...RequestDealFields
        } 
    }
  }
  
fragment IOIFileFields on IOIFileType {
    id
    name
    fileName
    link
    confirmed
    isOwnNegotiation
    fieldCamelCase
  } 
  ${RequestDealFields}
`;

export const GET_TOOLTIPS = gql`
  query ($requestType: Int){
    getTooltips(requestType: $requestType) {
      key
      title
      content
    }
  }
`;

/**
 * Mutation to get speed match fields
 */
export const GET_IOI_SPEED_MATCH_APPROVAL = gql`
  query ($id: ID!) {
    getIoi(id: $id) {
      ...RequestIOIFields
      
      id
      speedMatchStatus
      speedMatchStatusDisplay
      chatroom {
        id
        ioi {
          id
          request {
            id
          }
         }
        counterparty {
         id
        }
       counterpartyActiveChat
      }
      approval1 {
        id
        step
        status
        statusDisplay
        contactSet {
          id
          name
          role
          isApprove
        }
        reminderSentAt
      }
      approval2 {
        id
        step
        status
        statusDisplay
        contactSet {
          id
          name
          role
          isApprove
        }
        reminderSentAt
      }
      
      matchsummary {
        id
        summary
        history
        chat
      }
      statusDisplay
      speedMatchStatusDisplay
      investorName
      sponsorName
      loanTermFixed
      isMatchRequestOwner
      projectDescription {
        ...IOIFileFields
      }
      annualReport {
        ...IOIFileFields
      }
      investorPresentation {
        ...IOIFileFields
      }
      documentation {
        ...IOIFileFields
      }
      other {
        ...IOIFileFields
      }
      
      pendingApprovalNotification {
        id
        name
        notification {
          title
          message
        }
        approvalProcess {
          id
          status
          statusDisplay
        }
      }
      
      request {
        id
        requestType
        inSpeedMatchProcess
        dealName
        owner {
          id
          client {
            clientType
            category {
              id
              name
            }
          }
        }
        investorPresentation {
          id
          name
          fileName
          link
        }
        documentation {
          id
          name
          fileName
          link
        }
        annualReport {
          id
          name
          fileName
          link
        }
        other {
          id
          name
          fileName
          link
        }
      }
    }
  }
 
   ${RequestIOIFields}
`;
