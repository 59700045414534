import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourceEN from './en';
import resourceDE from './de';
import { APP_LANGUAGE, BACKEND_FRONTEND_LANGUAGES } from '../settings/constants';

const resources = {
  EN: {
    translation: resourceEN,
  },
  DE: {
    translation: resourceDE,
  },
};

const checkUndefined = (language) => {
  return typeof BACKEND_FRONTEND_LANGUAGES[language] === `undefined`
    ||  BACKEND_FRONTEND_LANGUAGES[language] === 'undefined';
}

let defaultLanguage;

if (typeof navigator !== `undefined`) {
  defaultLanguage = (navigator && navigator.language) ? navigator.language.toLowerCase() : '';
}

if (typeof window !== 'undefined') {
  defaultLanguage = localStorage.getItem(APP_LANGUAGE);
}

defaultLanguage = checkUndefined(defaultLanguage) ? null : defaultLanguage

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: BACKEND_FRONTEND_LANGUAGES[defaultLanguage || 'de'],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
