const routesDictionary = {
  noAccessInquiry: '/inquiry/no-access',
  passiveInvestorInquiry: '/inquiry/:uid/:id',
  finalizedTermSheet: '/termsheet/done/',
  mismatch: '/mismatch/deal/:id/',
  mismatchFunc: (id) => `/mismatch/deal/${id}/`,
  loanLandTermSheet: '/deal/termsheet/:id/',
  loanLandTermSheetFunc: (id) => `/deal/termsheet/${id}/`,
  approvalSummary: '/approval/summary',
  thankYou: '/thank-you/',
  revoked: '/revoked/',
  approvalDeal: '/approval/deal/:uid/',
  approvalDealLoanLand: '/deal/approval/ll/:id/',
  approvalDealLoanLandFunc: (id) => `/deal/approval/ll/${id}/`,
  loanLandNegotiation: '/deal/negotiation/ll/:id/',
  loanLandNegotiationFunc: (id) => `/deal/negotiation/ll/${id}/`,
  loanLandStartNegotiation: '/request/deal/:id/',
  loanLandStartNegotiationFunc: (id) => `/request/deal/${id}/`,
  requestDeal: '/deal/:id/',
  requestDealFunc: (id) => `/deal/${id}/`,
  loanLandCreateDeal: '/create/deal/:id/',
  loanLandCreateDealFunc: (id) => `/create/deal/${id}/`,
  downloadPDF: '/marketplace/ioi_file/:id/',
  accountSettings: '/account/settings/',
  marketplace: '/marketplace/',
  matchSummary: '/match/summary/:id/',
  matchSummaryFunc: (id) => `/match/summary/${id}/`,
  myDeals: '/deals/',
  dealNegotiationFunc: (id) => `/deal/negotiation/${id}/`,
  dealNegotiation: '/deal/negotiation/:id/',
  createRequest: '/create/request/:id/',
  createRequestFunc: (id) => `/create/request/${id}/`,
  register: '/register/',
  login: '/login/',
  forgotPassword: '/forgot/password/',
  setPassword: '/set/password/',
  resetPassword: '/reset/password/:uid/:token',
  admin: '/admin',
  error: '/404/',
  home: '/',
};

export default routesDictionary;
