import { gql } from 'apollo-boost';

/**
 * Mutation to change language
 * @param language
 */
export const CHANGE_LANGUAGE = gql`
  mutation ($language: String!){
    changeLanguage(language: $language) {
      fieldErrors {
        field
        messages
      }
      success
    }
  }
`;


/**
 * Mutation to accept terms
 * @param termsAndConditions
 */
export const ACCEPT_TERMS = gql`
  mutation ($termsAndConditions: ID!) {
    acceptTermsCondition(termsAndConditions: $termsAndConditions) {
      fieldErrors {
        field
        messages
      }
      success
    }
  }
`;


/**
 * Mutation to edit account settings
 * @param input
 */
export const EDIT_ACCOUNT_SETTINGS = gql`
  mutation ($input: EditUserInput!) {
    editUser(input: $input) {
      success
      fieldErrors {
        field
        messages
      }
    }
  }
`;
