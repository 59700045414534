import * as React from 'react';

export const ChatContext = React.createContext();

function chatReducer(state, action) {
  switch (action.type) {
    case 'UPDATE': {
      return { data: action.data };
    }
    case 'CHAT': {
      return { data: action.data };
    }
    case 'DELETE': {
      return { data: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function ChatProvider({ children }) {
  const [state, dispatch] = React.useReducer(chatReducer, { data: null });
  const value = { state, dispatch };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export function useChat() {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}
