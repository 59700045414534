import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import moment from 'moment-timezone';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

moment.tz.setDefault('Europe/Berlin');

const root = document.getElementById('root');
const appRoot = ReactDOM.createRoot(root);

appRoot.render(
        <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
