import { gql } from 'apollo-boost';

export const CouponFields = gql`
  fragment CouponFields on CouponConventionType {
    value: id
    label: name
    active
  }
`;

export const RatingsFields = gql`
  fragment RatingFields on RatingType {
      value: id
      agency
      type
      weight
      label: display
      hasInvestmentGrade
  }
`;

export const IssuerTypeFields = gql`
  fragment IssuerTypeFields on IssuerTypeType {
    value: id
    label: name
    description
  }
`;

export const InvestorTypeFields = gql`
  fragment InvestorTypeFields on InvestorTypeType {
    value: id
    label: name
  }
`;

export const DebtTypeFields = gql`
  fragment DebtTypeFields on DebtTypeType {
    value: id
    label: name
    active
    tokenized
  }
`;

export const CouponFeatureField = gql`
  fragment CouponFeatureField on CouponFeatureType {
    value: id
    label: name
  }
`;

export const CouponFrequencyField = gql`
 fragment CouponFrequencyField on CouponFrequencyType {
   value: id
   label: name
 }
`;

export const CouponDaycountField = gql`
  fragment CouponDaycountField on CouponDaycountType {
    value: id
    label: name
}
`;

export const SponsorTypeField = gql`
  fragment SponsorTypeField on SponsorTypeType {
    value: id
    label: name
  }
`;

export const AssetClassField = gql`
  fragment AssetClassField on AssetClassType {
    value: id
    label: name
  }
`;

export const BuildingConditionsField = gql`
  fragment BuildingConditionsField on BuildingConditionType {
    value: id
    label: name
  }
`;

export const TernureField = gql`
  fragment TernureField on TenureType {
    value: id
    label: name
  }
`;

export const FinancingPurposeField = gql`
  fragment FinancingPurposeField on FinancingPurposeType {
    value: id
    label: name
  }
`;

export const FinancingRankField = gql`
  fragment FinancingRankField on FinancingRankType {
    value: id
    label: name
  }
`;

export const TypeLoanField = gql`
  fragment TypeLoanField on TypeLoanType {
    value: id
    label: name
  }
`;

export const CollateralField = gql`
  fragment CollateralField on CollateralType {
    value: id
    label: name
  }
`;

export const BorrowerSectorField = gql`
  fragment BorrowerSectorField on BorrowerSectorType {
    value: id
    label: name
  }
`;

export const RequestFileFields = gql`
  fragment RequestFileFields on RequestFileType {
    id
    fileName
    link
}
`;

export const RequestDealFields = gql`
  fragment RequestDealFields on RequestType {
    id
    requestType
    inSpeedMatchProcess
    dealName
    owner {
     id
     client {
      clientType
      category {
        id
        name
       }
      }
    }
    investorPresentation {
      id
      name
      fileName
      link
     }
     documentation {
      id
      name
      fileName
      link
     }
     annualReport {
      id
      name
      fileName
      link
     }
     other {
      id
      name
      fileName
      link
     }
    }
`;

export const RequestIOIFields = gql`
  fragment RequestIOIFields on IOIType {
      id
      chatroom {
       id
       ioi {
        id
        request {
          id
        }
       }
       counterparty {
           id
         }
         counterpartyActiveChat
       }
       reminderSentAt
       requestOwner {
        id
        client {
            id
            clientType
          }
        }
         speedMatchStatus
       isMatchRequestOwner
       owner {
         id
         firstName
         client {
           clientType
         }
       }
       matchsummary {
        id
        summary
        history
        chat
       }
       status
       volumeStart
        volumeEnd
        volumeRangeOpen
        volume
        volumeOpen
        isin
        isinOpen
        issuerType{
          id
          name
          description
        }
        issuerNameOpen
        debtType {
          id
          name
          active
          tokenized
        }
        productCategory {
          id
          name
          selectable
        }
        productRatingOpen
        maturityRangeOpen
        maturity
        maturityOpen
        cleanPriceStart
        cleanPriceEnd
        cleanPriceRangeOpen
        cleanPriceOpen
        couponRangeOpen
        coupon
        couponOpen
        couponConventionOpen
        spreadRangeOpen
        spread
        spreadOpen
        benchmark {
          id
          name
        }
        benchmarkOpen
        yieldValueStart
        yieldValueEnd
        yieldValueRangeOpen
        yieldValue
        yieldValueOpen
        description
        descriptionOpen
        documentsOpen
        notes
        internalId
        settlementDate
        issuerName
        issuerCountry {
          id
          name
          code
          weight
        }
        currency {
          id
          name
          code
          sign
          signPosition
        }
        maturityStart
        maturityEnd
        maturityFixed
        spreadFixed
        spreadStart
        spreadEnd
        couponStart
        couponEnd
        couponFixed
        cleanPrice
        couponConvention {
          id
          name
          active
        }
        issuerNameVisible
        sponsorNameVisible
        borrowerNameVisible
        investorNameVisible
        issuerSector {
          id
          name
          description
        }
        investorName
        investorType {
          id
          name
        }
        investorCountry {
          id
          name
          code
          weight
        }
        issuerCreditQualityRating {
          id
          name: display
          parent {
            id
            name: display
          }
        }
        annualSales
        leverageRatio
        netDebt
        totalDebt
        totalInvestmentCosts
        financingAmountStart
        financingAmountEnd
        financingAmountFixed
        typeDebtInstrument{
          id
          name
          active
        }
        repayment{
          id
          name
        }
        couponFeature{
          id
          name
          parent {
            id
            name
          }
        }
        couponFrequency {
          id
        }
        couponDaycount{
          id
          name
        }
        deadline
        additionalInformation
        blacklistedCounterpartyCategories{
          id
          name
          hidden
        }
        blacklistedCounterparties{
          id
          companyName
          street
          houseNumber
          state
        }
        sponsorName
        sponsorType{
          id
          name
        }
        sponsorCountry{
          id
          name
          code
          weight
        }
        assetClass{
          id
          name
        }
        location{
          id
          name
        }
        country{
          id
          name
          code
          weight
        }
        address
        numberObjects
        lettableSpace
        rentalUnits
        numberTenants
        occupancyRate
        grossRentalIncomeActual
        grossRentalIncomeErv
        netOperatingIncome
        wault
        buildingCondition{
          id
          name
        }
        yearConstruction
        tenure{
          id
          name
        }
        financingPurpose{
          id
          name
        }
        financingRank{
          id
          name
        }
        loanTermStart
        loanTermEnd
        loanTermFixed
        marketValueEstimate
        ltc
        ltv
        debtYield
        typeLoan{
          id
          name
        }
        collateral{
          id
          name
        }
        amortization
        makewhole
        upfrontFee
        exitFee
        borrowerName
        borrowerSector{
          id
          name
        }
        borrowerCountry{
          id
          name
          code
          weight
        }
        fixedInterestPeriodStart
        fixedInterestPeriodEnd
        fixedInterestPeriodFixed
        issuerSector {
          id
        }
        # Files
        annualReport {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        investorPresentation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        documentation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        other {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        
        # Loandland files
        speedMatchStatus
        draftTermSheet
        headsOfTerms
        activatedTermSheet
        creditApproval
        facilityAgreement
        settlementDate
        settlementDateLoanland
        refinanceExistingDebt
        refinanceExistingShareholderLoan
        notary
        netPurchasePrice
        realStateTransferTax
        capex
        otherTransactionCosts
        totalSources
        financingAmount
        equityVolume
        totalInvestmentCostsLoanland
      
        projectDescription {        
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        rentRoll {        
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        propertyInformation {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        cashFlow {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
        kycChecklist {
          ...IOIFileFields
          isOwnNegotiation
          confirmed
        }
  }
  
   fragment IOIFileFields on IOIFileType {
    id
    name
    fileName
    link
    confirmed
    isOwnNegotiation
    fieldCamelCase
  }
`;

export const TermsSheetFields = gql`
  fragment TermsSheetFields on TermSheetSignedGqlType {
    id
    link
    accepted
    fileName
    termSheetType
    termSheetTypeDisplay
    uploadedAt
    uploadedBy {
      id
      firstName
      lastName
      client {
        id
        companyName
      }
    }
  }
`;

export const ChatMessage = gql`
fragment ChatMessage on ChatMessageType {
  id
  message
  sentAt
  readByReceiver
  counterpartyActiveChat
  sender {
    id
    client {
      id
    }
  }
}
`;
