import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row } from 'antd';
import logo from '../../media/images/logo_firstwire.png';
import './styles.scss';
import routesDictionary from '../../routes/routesDict';

const Logo = React.memo(({ header }) => {
  const navigate = useNavigate();

  return (
    <Row className="text-center">
      <img
        className={header ? 'responsive-header-logo' : 'responsive-logo'}
        src={logo}
        alt="logo"
        onClick={() => navigate(routesDictionary.home)}
      />
    </Row>
  );
});

Logo.propTypes = {
  header: PropTypes.bool,
};

Logo.defaultProps = {
  header: false,
};

export default Logo;
