import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import './styles.scss';
import moment from 'moment';
import { APP_LANGUAGE, BACKEND_FRONTEND_LANGUAGES } from '../../settings/constants';
import { CHANGE_LANGUAGE } from '../../graphql/mutations/General';
import { getAuthTokenName } from '../../utils/tools';
import 'moment/locale/de';

const propTypes = {
  userInformation: PropTypes.object,
};

const defaultProps = {
  userInformation: {},
};

function TranslationSelector({ userInformation, setUserInformation }) {
  const { i18n } = useTranslation();
  const client = useApolloClient();
  const [active, setActive] = useState();
  const { language: userLanguage } = userInformation;

  useEffect(() => {
    if (!Object.keys(userInformation).length) {
      const code = Object.keys(BACKEND_FRONTEND_LANGUAGES).find((key) => BACKEND_FRONTEND_LANGUAGES[key] === i18n.language);

      moment.locale(code.toLowerCase());
      localStorage.setItem(APP_LANGUAGE, code);
      setActive(i18n.language);
    }
  }, [i18n, userInformation]);

  useEffect(() => {
    if (userLanguage) {
      const code = Object.keys(BACKEND_FRONTEND_LANGUAGES).find((key) => key === userLanguage);

      moment.locale(code.toLowerCase());
      localStorage.setItem(APP_LANGUAGE, code);
      setActive(BACKEND_FRONTEND_LANGUAGES[code]);
      i18n.changeLanguage(BACKEND_FRONTEND_LANGUAGES[code]);
    }
  }, [i18n, userLanguage]);

  const onClickLanguage = (language, code) => {
    if (i18n.language === language) return;

    if (localStorage.getItem(getAuthTokenName())) {
      client.mutate({
        mutation: CHANGE_LANGUAGE,
        variables: {
          language: code,
        },
      }).then((response) => {
        localStorage.setItem(APP_LANGUAGE, code);
        setActive(language);
        setUserInformation({
          ...userInformation,
          language: code,
        });
        i18n.changeLanguage(language);
      }).catch((error) => console.log(error));
    } else {
      localStorage.setItem(APP_LANGUAGE, code);
      setActive(language);
      i18n.changeLanguage(language);
    }
  };

  return (
    <Row className="translation-selector">
      {Object.keys(BACKEND_FRONTEND_LANGUAGES).map((language, index) => {
        const languageItem = BACKEND_FRONTEND_LANGUAGES[language];

        return (
          <Col
            key={`language-col-${language}`}
            id={`language-col-${language}`}
            className={
            `language ${Object.keys(BACKEND_FRONTEND_LANGUAGES).length - 1 !== index && 'item-border-box'} 
            ${active === languageItem && 'selected'}`
          }
            span={12}
            onClick={() => {
              onClickLanguage(languageItem, Object.keys(BACKEND_FRONTEND_LANGUAGES)[index]);
            }}
          >
            {languageItem}
          </Col>
        );
      })}
    </Row>
  );
}

TranslationSelector.propTypes = propTypes;
TranslationSelector.defaultProps = defaultProps;

export default TranslationSelector;
