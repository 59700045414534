import { gql } from 'apollo-boost';
import { ChatMessage } from '../../fragments';

/**
 * Query to get a specific chat
 */
export const CHAT_INBOX_ROOM = gql`
  query ($ioiId: ID!) {
    getChatRoom(ioiId: $ioiId) {
      pendingMessages {
        ...ChatMessage
      }
      chatmessageSet {
        ...ChatMessage
      }
      id
      counterparty {
        id
        firstName
        lastName
        email
      }
      counterpartyActiveChat
      ioi{
        id
        owner {
          id
          firstName
          lastName
        }
        request {
          id
        }
      }
    }
  }

${ChatMessage}
`;

/**
 * Query to get all chats
 */
export const CHAT_INBOX_ROOMS = gql`
  query  {
  getChatRooms {
    id
    ioi {
      id
      owner {
        id
        firstName
        lastName
      }
      request {
        id
      }
    }
    counterparty {
      id
      firstName
      lastName
      email
    }
    counterpartyActiveChat
    pendingMessages {
      ...ChatMessage
    }
    chatmessageSet(limit: 1) {
      ...ChatMessage
    }
  }
}
${ChatMessage}
`;
