import React from "react";

import { Route } from 'react-router-dom';

import routes from "../../routes/routeList";
import MakeRouteWithSubRoutes from "../../routes";


const getRoutes = (props, userInformation, setUserInformation) => {
    const results = [];

    routes.forEach((item) => {
        /***
         item:
         {
             path: routesDictionary.noAccessInquiry,
             component: AsyncNoAccessInquiry,
             type: 'persistent',
             title: 'No Access',
         }
         ***/
        const routePath = {
            path: item.path,
            element: (
                <MakeRouteWithSubRoutes
                    id={item.path}
                    key={item.path}
                    {...props}
                    {...item}
                    userInformation={userInformation}
                    setUserInformation={setUserInformation}
                />
            ),
        };

        results.push(
            <Route
                key={item.path}
                {...routePath}
            />,
        );
    });

    return results;
}

export default getRoutes;
