import React, { useEffect, useState } from 'react';
import {
  Col, Collapse, List, Row, Tabs,
} from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowUp } from '../../media/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../media/icons/arrow_down.svg';
import Inbox from './Inbox';
import { useChat } from '../../wrappers/Context';
import { isUndefined } from '../../utils/tools';
import { getAllChats, getInboxDescription, updateListMessages } from './functions';
import './styles.scss';

const { TabPane } = Tabs;
const { Panel } = Collapse;

function ChatInbox({ userInformation }) {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [activeKey, setActiveKey] = useState([]);
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [pendingItem, setPendingItem] = useState(false);
  const { state, dispatch } = useChat();

  useEffect(() => {
    if (activeTab === '1') {
      getAllChats(client, setPendingItem, setChats);
    }
    // eslint-disable-next-line
  }, [client, t]);

  useEffect(() => {
    // Open chat collapse
    if (state.data && !isUndefined(state.data.chat)) {
      if (state.data.chat) {
        if (state.data.chatId) {
          setActiveChat({
            ioi: state.data.ioiId,
            deal: state.data.requestId,
          });
          setActiveTab('2');
        } else {
          setActiveTab('1');
        }

        setActiveKey(['1']);
      } else {
        setActiveKey([]);
      }
    }

    //  Update list of messages
    updateListMessages(client, state.data, chats, setPendingItem, setChats);
    // eslint-disable-next-line
  }, [state]);

  function callback(key) {
    setActiveTab(key);
  }

  return (
    <Row
      id="chat-inbox-container"
      data-test-id="chat-inbox-container"
    >
      <Col>
        <div className="chat-inbox-container">
          <Collapse
            expandIconPosition="right"
            id="chat-inbox-panel"
            activeKey={activeKey}
            expandIcon={({ isActive }) => (!isActive ? <ArrowUp className="arrow-panel-icon" />
              : <ArrowDown className="arrow-panel-icon" />)}
            className="chat-inbox-panel"
            onChange={(key) => {
              if (!key.length) {
                dispatch({
                  type: 'CHAT',
                  data: {
                    ...state.data,
                    chat: false,
                  },
                });
              }

              setActiveKey(key);
              setPendingItem(false);
            }}
          >
            <Panel
              header={(
                <div className="pending-item-container">
                  {' '}
                  {t('messages')}
                  {pendingItem ? <div className="pending-item" /> : ''}
                </div>
              )}
              key="1"
            >
              <div className="all-chat-inbox">
                <Tabs activeKey={activeTab} onChange={callback}>
                  <TabPane tab={t('negotiations')} key="1" className="list-overflow">
                    <List
                      itemLayout="horizontal"
                      dataSource={chats}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={`${t('deal')} #${item.ioi.request.id} - ${item.ioi.id}`}
                            description={getInboxDescription(t, userInformation, item)}
                            className={`p-1 ${item.new || item.pendingMessages?.length ? 'new-message' : ''}`}
                            onClick={() => {
                              item.new = '';
                              item.pendingMessages = [];
                              setActiveChat({
                                ioi: item.ioi.id,
                                deal: item.ioi.request.id,
                              });
                              setActiveTab('2');
                            }}
                          />
                        </List.Item>
                      )}
                    />
                  </TabPane>
                  <TabPane tab={t('chat')} key="2">
                    {activeKey.length && activeChat
                      ? (
                        <Inbox
                          chat={activeChat}
                          ioiId={activeChat.ioi}
                          userInformation={userInformation}
                          chats={chats}
                          setChats={setChats}
                        />
                      )
                      : (
                        <div className="chats-inbox">
                          <div className="input-chat-container-empty">
                            <div className="empty-chat">
                              {t('emptyChat')}
                            </div>
                          </div>
                        </div>
                      )}
                  </TabPane>
                </Tabs>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
}

ChatInbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userInformation: PropTypes.object,
};

ChatInbox.defaultProps = {
  userInformation: {},
};

export default ChatInbox;
