import React, { useEffect, useState } from 'react';
import {Routes, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApolloClient, useSubscription } from '@apollo/client';
import i18n from 'i18next';
import getRoutes from './utils';
import ShowTerms from '../../views/Register/ShowTerms';
import routesDictionary from '../../routes/routesDict';
import { ME } from '../../graphql/queries/User';
import { getAuthTokenName } from '../../utils/tools';
import { CHAT_GENERAL } from '../../graphql/subscriptions';
import { useChat } from '../Context';
import { APP_LANGUAGE, BACKEND_FRONTEND_LANGUAGES } from '../../settings/constants';

function LayoutView(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: chatRooms = {} } = useSubscription(CHAT_GENERAL);
  const { state, dispatch } = useChat();

  const client = useApolloClient();
  const [userInformation, setUserInformation] = useState({
    firstName: 'User',
    lastName: '',
    companyName: 'BlitZz',
  });
  const authToken = localStorage.getItem(getAuthTokenName());

  useEffect(() => {
    const missingTerms = localStorage.getItem('terms') === 'true' && location.pathname !== routesDictionary.home;
    if (missingTerms && props.isLogin) {
      setIsModalVisible(true);
    }
  }, [props.isLogin]);

  useEffect(() => {
    if (chatRooms.chatGeneralSubscription) {
      const { userConnected, chatMessage } = chatRooms.chatGeneralSubscription;

      dispatch({
        type: 'UPDATE',
        data: {
          userConnected: userConnected || state.userConnected,
          chatMessage: chatMessage || state.chatMessage,
        },
      });
    }
  }, [chatRooms]);

  useEffect(() => {
    if (props.isLogin && authToken) {
      client.query(
        {
          query: ME,
          fetchPolicy: 'no-cache',
        },
      ).then((response) => {
        const { me } = response.data;
        if (me) {
          localStorage.setItem(APP_LANGUAGE, me.language);
          i18n.changeLanguage(BACKEND_FRONTEND_LANGUAGES[me.language]);
          setUserInformation(me);
        }
      }).catch((error) => console.log(error));
    }
  }, [client, props.isLogin, authToken]);

  return (
      <>
        <div key="layout-view" className="h-100">
          <Routes>
            {getRoutes(props, userInformation, setUserInformation)}
          </Routes>
        </div>
        {isModalVisible && (
            <ShowTerms
                key="layout-view-terms"
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                closable={false}
            />
        )}
      </>
  );
}

LayoutView.propTypes = {
  isLogin: PropTypes.string,
};

LayoutView.defaultProps = {
  isLogin: '',
};
const areEqual = (prevProps, nextProps) => {
  return (
      (prevProps.isLogin === nextProps.isLogin) &&
      (prevProps.userInformation === nextProps.userInformation)
  );
};
export default React.memo(LayoutView, areEqual);