import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/react-hooks';
import { getRefreshTokenName, logout, setLoginTokens } from '../../utils/tools';
import { API_URL } from '../../settings/constants';

const expiredToken = 'Signature has expired';
const refreshTokenExpired = 'Session expired';
const signatureToken = 'Error decoding signature';

export const onExpiredToken = (url) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  const obj = {
    operationName: null,
    query: `mutation ($refreshToken: String!) {
      refreshToken(refreshToken: $refreshToken) {
          payload
          refreshExpiresIn
          token
          refreshToken
      }
    }`,
    variables: { refreshToken: localStorage.getItem(getRefreshTokenName()) },
  };
  xhr.open('POST', url || '');
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onload = (e) => {
    const { responseText } = e.target;
    const parseResponse = JSON.parse(responseText);

    if (parseResponse.data) {
      const { refreshToken } = parseResponse.data;
      if (refreshToken) {
        setLoginTokens(refreshToken.token, refreshToken.refreshToken);

        return resolve({
          token: refreshToken.token,
          refreshToken: refreshToken.refreshToken,
        });
      }
    }
    return reject(Error(refreshTokenExpired));
  };

  xhr.onerror = reject;

  xhr.send(JSON.stringify(obj));
});

export const refreshTokenLink = (setLoginState) => onError(
  (response) => {
    const {
      graphQLErrors, operation, forward,
    } = response;
    if (graphQLErrors) {
      console.log(graphQLErrors);
      const [firstError] = graphQLErrors;

      if (firstError.message === expiredToken) {
        const rememberAccount = localStorage.getItem('rememberMe') === 'true';

        if (rememberAccount) {
          return fromPromise(
            onExpiredToken(API_URL).catch(() => {
              logout(setLoginState);
            }),
          ).flatMap((values) => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `JWT ${values.token}`,
              },
            });

            return forward(operation);
          });
        }
        //  logout
        logout(setLoginState);
      }
      if (firstError.message === signatureToken) {
        //  logout
        logout(setLoginState);
      }

      return undefined;
    }

    return undefined;
  },
);
