import React from 'react';
import {Navigate, Route, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import routesDictionary from './routesDict';
import HeaderComponent from '../components/Header';
import ChatInbox from '../components/Chat';
import useLocationQuery from "../customHooks/useLocationQuery";

const routeTypes = {
  private: (props) => {
    const {
      renderProps, component: Component,
      isLogin, setLoginState, userInformation, setUserInformation, queryString, location,
    } = props;

    const nextUrl = queryString.get('next');

    if (!isLogin) {
      return (
        <Navigate
          to={{
            pathname: routesDictionary.login,
            search: `next=${location.pathname}`,
          }}
        />
      );
    }

    if (nextUrl) {
      return (
          <Navigate
              to={nextUrl}
          />
      );
    }

    return (
      <HeaderComponent
        setLoginState={setLoginState}
        userInformation={userInformation}
        setUserInformation={setUserInformation}
      >
        <Component
          setLoginState={setLoginState}
          {...renderProps}
          userInformation={userInformation}
        />
        <ChatInbox userInformation={userInformation} />
      </HeaderComponent>
    );
  },
  public: (props) => {
    const {
      renderProps, component: Component,
      isLogin, setLoginState, queryString, location,
    } = props;

    if (isLogin) {
      return (
        <Navigate
          to={queryString.get('next') || routesDictionary.home}
        />
      );
    }

    return (
      <Component setLoginState={setLoginState} {...renderProps} />
    );
  },
  persistent: (props) => {
    const {
      renderProps, component: Component,
      setLoginState, queryString, location,
    } = props;

    return (
      <Component setLoginState={setLoginState} {...renderProps} />
    );
  },
};

function MakeRouteWithSubRoutes(props) {
  const {
    component: Component, type,
    isLogin, setLoginState,
    userInformation, setUserInformation,
  } = props;

  const location = useLocation();
  const queryString = useLocationQuery();

  return (
      routeTypes[type]({
        renderProps: props,
        setLoginState,
        component: Component,
        isLogin,
        userInformation,
        setUserInformation,
        location,
        queryString,
      })
  );
}

MakeRouteWithSubRoutes.propTypes = {
  /**
   * If user is Logged
   */
  isLogin: PropTypes.string,
  /**
   * Function to change application state to login
   */
  setLoginState: PropTypes.func.isRequired,
  /**
   * All user information
   */
  // eslint-disable-next-line react/forbid-prop-types
  userInformation: PropTypes.object.isRequired,
  /**
   * Function to update user information
   */
  setUserInformation: PropTypes.func.isRequired,
};

MakeRouteWithSubRoutes.defaultProps = {
  type: 'private',
  isLogin: false,
};

export default MakeRouteWithSubRoutes;
