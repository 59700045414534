import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_TERMS } from '../../../graphql/queries/General';
import { ACCEPT_TERMS } from '../../../graphql/mutations/General';
import { showNotification, showNotificationInfo } from '../../../components/Notification';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../settings/constants';
import './styles.scss';

function ShowTerms(
  {
    isModalVisible, setIsModalVisible, setTermsVersion, onlyRead, closable,
  },
) {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [termsAndConditions, setTermsAndConditions] = useState({});

  const getTerms = () => {
    client.query({
      query: GET_TERMS,
    }).then(({ data }) => {
      const terms = data && data.getLastTermsCondition;
      setTermsAndConditions(terms);
      setTermsVersion(terms.id);
    }).catch((error) => console.log(error));
  };

  useEffect(getTerms, [client, t, setTermsVersion]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAccept = () => {
    client.mutate({
      mutation: ACCEPT_TERMS,
      variables: {
        termsAndConditions: termsAndConditions.id,
      },
    }).then(({ data }) => {
      const { success, fieldErrors } = data.acceptTermsCondition;

      if (success) {
        showNotificationInfo(
          t('operationSuccess'),
          SUCCESS_NOTIFICATION,
          t('operationSuccess'),
        );
        localStorage.removeItem('terms');
      } else {
        showNotificationInfo(
          t('somethingWentWrong'),
          ERROR_NOTIFICATION,
          fieldErrors[0].messages[0],
        );
      }

      // Close modal
      setIsModalVisible(false);
    }).catch((error) => console.log(error));
  };

  return (
    <Modal
      id="terms-modal"
      data-test-id="terms-modal"
      title={t('termsAndConditionsTitle')}
      visible={isModalVisible}
      onCancel={closable ? handleCancel : null}
      footer={onlyRead ? (
        <Button key="back" onClick={handleCancel}>
          {t('returnBtn')}
        </Button>
      ) : (
        <Button key="accept" onClick={handleAccept}>
          {t('acceptBtn')}
        </Button>
      )}
      className="terms-modal"
      maskClosable={closable}
    >
      {termsAndConditions.body && <div dangerouslySetInnerHTML={{ __html: termsAndConditions.body }} />}
    </Modal>
  );
}

ShowTerms.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  setTermsVersion: PropTypes.func,
  onlyRead: PropTypes.bool,
  closable: PropTypes.bool,
};

ShowTerms.defaultProps = {
  setTermsVersion: () => {
  },
  onlyRead: false,
  closable: true,
};

export default ShowTerms;
