import { gql } from 'apollo-boost';

/**
 * Mutation to log in
 * @param email
 * @param password
 */
export const GET_GENDER = gql`
  query {
    getGenderOptions {
      value
      label
    }
 }
`;

export const CHECK_RESET_PASSWORD_LINK = gql`
query checkResetPasswordLink($uid: String!, $token: String!){
  checkResetPasswordLink(uid: $uid, token: $token){
    success
    feedback {
      message
      type
    }
  }
}
`;

export const ME = gql`
{
  me {
    id
    email
    firstName
    lastName
    isActive
    birthdate
    gender
    language
    companyName
    firstPasswordSet
    cellphone
    phone
    client {
      id
      isLoanland
      clientType
    }
    ioiReceivedEmail
    matchRequestEmail
    matchMismatchEmail
    dailyEmail
    userPermissions {
      id
      name
    }
    allPermissions {
      id
      name
      codename
    }
  }
}
`;
