import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Col, Layout, Menu, Row,
} from 'antd';
import routesDictionary from '../../routes/routesDict';
import { ReactComponent as UserIcon } from '../../media/images/user_icon.svg';
import { ReactComponent as SettingsIcon } from '../../media/icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../media/icons/logout.svg';
import { ReactComponent as ArrowIcon } from '../../media/images/arrow.svg';
import TranslationSelector from '../TranslationSelector';
import Logo from '../Logo';
import { logout } from '../../utils/tools';
import './styles.scss';

const { Content } = Layout;
const { SubMenu } = Menu;

const HeaderComponent = ({ setLoginState, userInformation, setUserInformation, children }) => {
  const userName = `${userInformation?.firstName} ${userInformation?.lastName}`;
  const companyName = userInformation?.companyName;
  const navigate = useNavigate();
  const location = useLocation();
  const pathname  = location.pathname;
  const showHeader = pathname !== routesDictionary.setPassword;
  const { t } = useTranslation();

  const menuTitle = (
    <Row>
      <Row>
        <UserIcon className="user-icon" />
      </Row>
      <Row className="user-information-container">
        <span className="user-name">{userName}</span>
        <span className="user-description">{companyName}</span>
      </Row>
      <Row span={2} className="down-arrow">
        <ArrowIcon className="down-arrow-icon" />
      </Row>
    </Row>
  );

  if (!showHeader) {
    return <div>{children}</div>;
  }

  return (
    <>
      <Row className="header w-100">
        <Col offset={1} className="header-logo mt-1">
          <Logo />
        </Col>
        <Col>
          <p className="m-0 your-market">
            {t('yourMarket')}
          </p>
        </Col>
        <Col className="header-user-information">
          <Row className="translation-selector">
            <TranslationSelector userInformation={userInformation} setUserInformation={setUserInformation} />
          </Row>
          <Row className="responsive user-info-container" align="center">
            <Menu key="Menu" className="menu" mode="horizontal">
              <SubMenu key="SubMenu" title={menuTitle}>
                <Menu.Item
                  key="setting-icon"
                  className="sub-menu"
                  icon={<SettingsIcon />}
                  onClick={() => navigate(routesDictionary.accountSettings)}
                >
                  <span className="dropdown-header">
                    {t('accountSettings')}
                  </span>
                </Menu.Item>
                <Menu.Item
                  key="logout-icon"
                  className="sub-menu"
                  icon={<LogoutIcon />}
                  onClick={() => logout(setLoginState)}
                >
                  <span className="dropdown-header">
                    {t('logOut')}
                  </span>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Row>
        </Col>
      </Row>
      <Content className="children-container">
        {children}
      </Content>
    </>
  );
};

export default HeaderComponent;
