/*
  English Resource
 */

const resourceDE = {
  // Header
  yourMarket: 'Your Market. Your Community.',
  accountSettings: 'Account Settings',
  logOut: 'Log out',

  registerDescription: 'You do not have a user account yet?',
  registerBtn: 'Register now',
  demoTitle: 'Experience firstwire Demo!',
  andMore: '...and much more',
  accessOver: 'Experience Blockchain Technology Live',
  crossIndustry: 'Use versatile features',
  flexibleRequest: 'Negotiate Deals in real time',
  emailAddress: 'Email address',
  emailAddressPH: 'Your email address',
  password: 'Password',
  passwordPH: 'Your password',
  welcomeAgain: 'Welcome back!',
  forgotPassword: 'You <0>forgot your password<0>?',
  loginBtn: 'Log in',
  rememberAccount: 'Stay logged in on this device. If this field is checked, we set cookies on the device',
  registerTitle: 'Register here and get started!',
  gender: 'Salutation',
  genderPH: 'Choose your salutation',
  firstName: 'First name',
  firstNamePH: 'Enter your first name',
  lastName: 'Last name',
  lastNamePH: 'Enter your last name',
  companyName: 'Company name',
  companyNamePH: 'Enter your company name',
  emailRegisterPH: 'Enter your email',
  cellphone: 'Phone number',
  cellphonePH: 'Enter your phone number',
  termsAndConditions: '<0>I give consent to the collection, processing and use of my data in accordance with the privacy policy. I have read and accept the </0> <1>Privacy Policy</1><0>  and the </0><2>Terms of Use</2>',
  registerFormBtn: 'Register',
  returnBtn: 'Return',
  acceptBtn: 'Accept',
  termsAndConditionsTitle: 'Terms and Conditions',
  phoneErrorFormat: 'Please enter valid format only',
  alreadyAccount: 'Do you already have an account? <0>Log in here</0>',
  emailPH: 'Enter your email',
  forgotPasswordTitle: 'Reset password',
  forgotPasswordDescription: 'Enter the email address you registered with here. We will send you an email with a link to reset your password.',
  sendBtn: 'Submit',
  goBackBtn: 'Back',
  goMyDealsBtn: 'My deals',
  loginFeedbackError: 'Please enter a correct email address and your password. Pay attention to upper and lower case letters.',
  setPasswordTitle: 'Set Password',
  youEmailAddressPH: 'Your email address',
  sendMailDescription: 'Please check your mailbox and click on the password reset link in the firstwire email',
  sendMailTitle: 'We have sent you an email!',
  goHomeBtn: 'Back to home page',
  setPasswordDescription: 'Please input your password',
  setPasswordOK: 'Your password was successfully changed!',
  resetPasswordTitle: 'Reset Password',
  resetPasswordDescription: 'Introduce a new password',
  passwordChanged: 'The password has changed',
  invalidLinkResetPasswordTitle: 'Invalid Link!',
  operationSuccess: 'Changes successful!',
  or: 'or',
  additionalInfo: 'AdditionalInformation',
  additionalInfoPH: '',
  selectFile: 'Select file',
  filePH: 'File',
  newAccount: 'You don\'t have an account yet? <0>Register now<0>',
  successRegisterAccount: 'Thank you for registering!',
  ok: 'OK',
  warningNotification: 'Warning',
  errorNotification: 'Error',
  successNotification: 'Success',
  partOf: 'You\'re part of',
  specifyYourDeal: 'ABOUT YOUR INQUIRY',
  dealNumber: 'Inquiry Number',
  dealType: 'Deal Type',
  saveInMyDeals: 'Save Draft in "My Deals"',
  mandatory: 'mandatory',
  dealName: 'My Inquiry Name',
  collapseAll: 'Collapse All',
  expandAll: 'Expand All',
  onlyVisible: '(only visible for you)',
  liveMarket: 'LIVE MARKET Interest',
  counterparties: 'Investors',
  volume: 'Volume',
  numberTenantTooltip: 'Some further information<0 />Can be displayed here.',
  liveMarketInterestTooltip: 'Here you can see how many potential investors match your financing request and what cumulative financing amount they can provide.',

  // Errors
  emailFormat: 'Please enter a valid email address.',
  requiredField: 'This field is required.',
  passwordRuleSize: 'Be at least eight characters long.',
  atLeastOneDigit: 'Contain at least one number.',
  atLeastOneSmallCase: 'Contain at least one lowercase letter.',
  atLeastOneUpperCase: 'Contain at least one uppercase letter.',
  atLeastOneSpecialCase: 'Contain at least one special character.',
  somethingWentWrong: 'Something went wrong!',
  onlyPositiveNumber: 'Only positive numbers',
  maxDigits: 'Please do not enter more than {{ digits }} digits ',
  maxNumber: 'Ensure this value is less than or equal to {{ max_number }}',
  yearDigits: 'Please enter a 4 digit number.',

  // Error fields
  visibleField: 'This field must be visible.',
  fillOutField: 'Please fill out this field.',
  selectOneOption: 'Please select only one option.',
  noDecimals: 'Please do not enter decimals.',
  positiveNumber: 'Please enter a number > 0.',
  floatNumber: 'Please do not enter more than 2 decimals.',
  floatNumberDigits: 'Please do not enter more than {{ max_decimals }} decimals.',
  enterNumber: 'Please enter a number.',
  atLeastCounterparty: 'Please allow at least one Counterparty Category.',
  dateFormat: 'Please enter a date as DD.MM.YYYY.',
  percentFormat: 'Please enter a valid number.',
  validNumber: 'Please enter a valid number.',
  invalidRange: 'Invalid range values.',
  atLeastOneDisabled: 'Please allow at least one Counterparty Category.',
  greaterThanEqualOne: 'Please enter a number >= 1.',
  greaterThanEqualZero: 'Please enter a number >= 0.',
  fileError: 'Invalid file type. Please use .doc, .docx., .jpeg, .pdf, .png, .ppt, .pptx, .tiff, .xls or .xlsx file, not exceeding 10 MB each.',

  // Sections
  dealParticipants: 'Deal Participants',
  objectInformation: 'Object Information',
  assetInformation: 'Asset Information',
  financingRequest: 'Financing Request',
  documentsNotes: 'Documents and Notes',
  OnlyNotes: 'Notes',
  privateInformation: 'Private Information',
  issuerFinancials: 'Issuer Information',
  dealSummary: 'Summary',
  dealDocuments: 'Deal Documents',
  continueNegotiating: 'Continue Negotiation',
  submitMatch: 'Submit Match',

  // Home
  realState: 'Real Estate',
  securedDebt: 'Secured Debt',
  unsecuredDebt: 'Unsecured Debt',
  corporate: 'Corporate',
  municipality: 'Municipality',
  firstwireTemplate: 'firstwire Template',
  letsGo: 'Let\'s Go',
  specifyDeal: 'Please select a template from below and start your Inquiry.',
  letsSpecifyYourDeal: 'Let\'s specify your Inquiry',
  whatCreate: 'What kind of Inquiry do you want to create?',
  dealReady: 'Your Inquiry is ready!',
  pleasePublish: 'Submit it to the Marketplace and find a Match. The Inquiry will be automatically added to "My Deals".',
  submitToMarket: 'Submit To Marketplace',
  refineDeal: 'Refine your Inquiry',
  findInvestor: 'Get funding',
  create: 'Create',
  searchDeals: 'INVESTING',
  createDeal: 'Create your inquiry',
  viewDeals: 'View deals',
  explore: 'Explore',
  myDealsCard: 'View activities',
  myDealsText: 'Manage your Inquiries and close your Deals.',
  showMe: 'Show me',
  dealTotalHome: 'Currently {{ number }} Deals waiting to be matched',
  exploreMarketplace: 'Explore the Marketplace and find your matching Deal to invest.',
  createTextHome: 'Access 350 investors with > EUR {{investableCapital}} in investable capital.',
  qualifyDeal: 'You Qualify for a speed match!',
  speedMatchOption: 'The Speed Match option will start the process exclusively with LOANLAND. The process will be faster than the regular one.',
  afterFilling: 'After providing further information, you will',
  getFeedback: 'get feedback within the next 2 business days.',
  ifNotConnect: 'If you do not want to connect with LOANLAND you can still publish your Inquiry on the Marketplace.',
  startSpeedMatch: 'Start Speed Match Process',
  moreDetails: 'You want more Details about LOANLAND and Speed Match?',
  loanLandDetailsTitle: 'LOANLAND is THE provider of fast and reliable real estate debt.',
  loanLandDetailsPrimary: 'LOANLAND is a real estate private debt specialist based in Frankfurt and acts as investment manager for both credit funds and individual mandates. LOANLAND provides customized loan solutions and offers whole loans with attractive LTVs.',
  loanLandDetailsSecondary: 'LOANLAND and firstwire have exclusively partnered to offer the Speed Match process, a technology-based fast track solution for loan requests that meet LOANLAND’s lending criteria. Speed Match relies on a seamless and fully digitalized workflow covering all steps from the initial loan request to negotiations and termsheet signing.',
  loanLandDetailsLink: 'Check out the Website',
  newsSlogan: 'WHAT’S NEW <0>@firstwire</0>',
  newsDescription: 'Check for updates and latest news.',

  // Form
  sponsorName: 'Sponsor Name',
  sponsorType: 'Sponsor Type',
  sponsorCountry: 'Sponsor Country',
  borrowerName: 'Borrower Name',
  borrowerCountry: 'Borrower Country',
  borrowerSector: 'Borrower Sector',
  issuerName: 'Issuer Name',
  issuerSector: 'Issuer Sector',
  issuerCountry: 'Issuer Country',
  investorName: 'Investor Name',
  investorenType: 'Investor Type',
  investorType: 'Investor Type',
  investorCountry: 'Investor Country',
  issuerCreditQualityRating: 'Issuer Credit Quality & Rating',
  annualSales: 'Annual Sales',
  leverageRatio: 'Leverage Ratio',
  netDebt: 'Net Debt/EBITDA',
  totalDebt: 'Total Debt/EBITDA',
  assetClass: 'Asset Class',
  location: 'Location',
  country: 'Country',
  address: 'Address',
  numberObjects: 'Number of Objects',
  lettableSpace: 'Lettable Space',
  rentalUnits: 'Rental Units',
  numberTenants: 'Number of Tenants',
  occupancyRate: '(Pre-) Occupancy rate',
  grossRentalIncome: 'Gross Rental Income (actual) p.a.',
  grossRentalIncomeERV: 'Gross Rental Income (expected) p.a.',
  netOperatingIncome: 'Net Operating Income (actual) p.a.',
  wault: 'WAULT',
  buildingCondition: 'Building Condition & ESG',
  yearConstruction: 'Year of Construction',
  tenure: 'Tenure',
  financingPurpose: 'Financing Purpose',
  financingRank: 'Financing Type',
  financingAmount: 'Financing Amount',
  financingAmountStart: '',
  financingAmountEnd: '',
  currency: 'Currency',
  loanTerm: 'Loan Term',
  loanTermStart: '',
  loanTermEnd: '',
  fixedInterestPeriod: 'Fixed interest Period',
  marketValue: 'Market Value (estimate)',
  totalInvestmentCosts: 'Total Investment Costs',
  ltc: 'Minimum LTC',
  ltv: 'Minimum LTV',
  debtYield: 'Debt Yield',
  maturity: 'Maturity',
  typeLoan: 'Type of Loan',
  typeDebtInstrument: 'Debt Instrument',
  collateral: 'Collateral & Financial Covenants',
  spread: 'Spread',
  spreadStart: '',
  spreadEnd: '',
  coupon: 'Coupon',
  couponStart: '',
  couponEnd: '',
  cleanPrice: 'Clean Price',
  amortization: 'Amortization p.a.',
  repayment: 'Repayment',
  couponFeatures: 'Coupon Feature',
  couponFrequency: 'Coupon Frequency',
  couponDaycount: 'Coupon Day-Count Convention',
  couponConvention: 'Coupon Business Day Convention',
  makewhole: 'Makewhole',
  upfrontFee: 'Upfront Fee',
  exitFee: 'Exit Fee',
  deadline: 'Expiry Date',
  additionalInformation: 'Additional Information',
  documents: 'Documents',
  investorPresentation: 'Investor presentation',
  documentation: 'Documentation',
  annualReport: 'Annual Report',
  other: 'Other Documents',
  transactionDocuments: 'Transaction Documents',
  investorRelationsDocuments: 'Investor Relations Documents',
  KYCAMLDocuments: 'KYC/AML Documents',
  budgetRelatedDocuments: 'Budget related Documents',
  assetRelatedDocuments: 'Asset related Documents',
  sponsorRelatedDocuments: 'Sponsor related Documents',

  blacklistedCounterparties: 'Blacklisted Counterparties',
  blacklistedCounterpartyCategories: 'Blacklisted Counterparty Categories',
  saveSuccess: 'Save successful',
  submitSuccess: 'Deal submitted',
  parties: 'Parties',

  // Multiselect
  passLimitMessage: 'The number of results exceeds {{ limit }}. Try to be more precise with your search',
  itemsSelected: '{{ items }} items selected',

  // My Deals
  myDeals: 'My Deals',
  showMore: 'Show More',
  showLess: 'Show Less',
  active: 'Active',
  matched: 'Matched',
  mismatched: 'Mismatched',
  closed: 'Closed',
  failed: 'Failed',
  allNegotiations: 'All Deals',
  onMarketplace: 'On Marketplace',
  offMarketplace: 'Off Marketplace',
  draft: 'Draft',
  negotiationForInv: 'Deal',
  negotiationsForInv: 'Deals',
  dealNameTitle: 'Inquiry Name',
  debtTypeTitle: 'Debt Type',
  moreInfo: 'More Info',
  matchSummary: 'Match summary',
  negotiation: 'Negotiation',
  sqm: 'sqm',
  pleaseSelect: 'Please Select',
  search: 'Start typing',

  // Deals
  findDeal: 'Find Your matching deal',
  mainCategories: 'MAIN CATEGORIES OF DEAL Types',
  pleaseSpecifyFilter: 'Please specify further',
  resetFilter: 'RESET FILTER',
  lookingForInvest: 'You are looking to invest?',
  lookingForFunding: 'You are looking for funding?',
  showResults: 'Show Results',
  borrowerType: 'Borrower Type',
  dealId: 'Deal ID',
  results: 'Results',
  withdrawMarketplace: 'Withdraw from Marketplace',
  withdrawMarketplaceSuccess: 'Deal withdrawn',
  share: 'Share',
  transfer: 'Transfer',

  // Account Settings
  generalInfo: 'General info',
  emailNotifications: 'EMAIL notifications',
  permissionGranted: 'USER RIGHTS',
  company: 'Company',
  email: 'Email',
  telephone: 'Telephone',
  cellphoneSetting: 'Mobile phone',
  changePasswordSetting: 'To change your password please',
  clickHere: 'Click here',
  save: 'Save',
  myCellphone: 'My mobile number',
  newIOI: 'On new IOI',
  matchOptions: 'Can do match actions',
  missMatchOptions: 'Can mismatch',
  termsAccepted: 'Here you can read the current <0>Terms and Conditions</0> that you have accepted',
  ioiReceivedEmail: 'New Deal',
  matchRequestEmail: 'Match Request',
  matchMismatchEmail: 'Match or Mismatch',
  dailyEmail: 'Daily Overview',
  foldAll: 'Fold all',
  deal: 'Deal',
  startNegotiation: 'Start a DEAL',

  // Negotiation
  visible: 'visible',
  hidden: 'hidden',
  showHistory: 'SHOW HISTORY',
  mandatoryMatching: 'Mandatory for matching',
  stopNegotiation: 'STOP DEAL',
  matchBtn: 'MATCH',
  onlyOneSelected: 'Please select only one option.',
  pleaseFillIn: 'Please fill in',
  requestVisible: 'REQUEST VISIBILITY',
  notShared: 'Not shared',
  stopRequest: 'STOP REQUEST',
  requestVisibility: 'Request Visibility',
  feedbackPending: 'Feedback pending',
  submittedCounterparty: 'The Information was submitted to your Counterparty',
  feedbackCounterparty: 'Your feedback was submitted to the counterparty',
  negotiationStopped: 'Deal stopped',
  openNegotiationMessage: 'New Information',
  counterpartyName: 'Counterparty Name',
  counterpartyCategory: 'Counterparty Category',
  acceptedNegotiation: 'Value was accepted',
  declinedNegotiation: 'Value was declined',
  pleaseAddNewValue: 'Please enter new value',
  pleaseFillMandatory: 'Please fill in mandatory information before proceeding to match.',
  sendReminderOK: 'Reminder has been successfully sent.',
  negotiationWithDraw: 'You were successfully withdrawn from the deal.',
  negotiationMatch: 'Match',
  matchRequestWasSubmitted: 'Match Request was submitted',
  viewSummary: 'View Summary',
  confirmation: 'Confirmation',
  pending: 'Pending',
  matchEnquiry: 'Match Enquiry',
  sendReminder: 'Send reminder',
  withDrawnMessage: 'WITHDRAW MATCH REQUEST',
  haveMatch: 'Congratulations, you have a Match.',
  itsMatch: 'It’s a match!',
  goToLobby: 'Go back to lobby',
  yourMatchRequest: 'Your Match Request was Submitted',
  confirmationFeedback: 'Your counterparty is reviewing your Match Request. We will inform you about any updates.',
  goBackToLobby: 'Go Back to THE LOBBY',
  areYouSure: 'ARE YOU SURE?',
  areYouSureYouWantMissMatch: 'Are you sure you want to mismatch?',
  withdrawYourMatchRequest: 'Do you want to withdraw your Match Request?',
  withdrawAndContinueNegotiation: 'withdraw match request and continue negotiation',
  withdrawAndStopNegotiation: 'withdraw match request and stop negotiation',
  negotiationDeclined: 'Deal declined',

  //  LoanLand speed match
  loanLandMessage: 'To receive feedback within TWO business days, please submit the requested information (fields and templates).',
  sourceFunds: 'Sources of Funds',
  usesFunds: 'Uses of Funds',
  equityVolume: 'Equity Volume',
  totalSources: 'Total Sources',
  refinanceExistingDebt: 'Refinance existing Debt',
  refinanceExistingShareholderLoans: 'Refinance existing Shareholder Loans',
  netPurchasePrice: 'Net Purchase Price',
  realEstateTransferTax: 'Real Estate Transfer Tax',
  notary: 'Notary',
  otherTransactionCosts: 'Other Transaction Costs',
  capex: 'Capex',
  headsTerms: 'Heads of Terms',
  draftTermSheet: 'Draft Termsheet',
  activatedTermSheet: 'Activated Termsheet',
  creditApproval: 'Credit Approval',
  facilityAgreement: 'Facility Agreement',
  settlementDateValuta: 'Settlement Date/Valuta',
  projectDescription: 'Project Description',
  rentRoll: 'Rent Roll',
  propertyInformation: 'Property Information',
  cashFlow: 'Cash Flow & Business Plan Summary',
  kycChecklist: 'KYC Checklist',
  documentsSpeedMatch: 'Documents for Speed Match',
  projectTimeline: 'Project Timeline',

  // banner speed match
  congratulations: 'congratulations!',
  congratulationsMessage: 'You are now in bilateral deal with <0>LOANLAND</0> <1 /> <2>- the </2>fast and reliable provider of Real Estate Debt.',
  submitLoanLand: 'Submit to LoanLand',
  useProvideTemplate: 'Please use provided "templates".',
  loanLandTemplates: 'LoanLand Templates',
  loanLandSection: 'LOANDLAND Section',
  addDocument: 'Add document',
  saveSuccessLL: 'The information was saved',
  submitSuccessLL: 'The information was submitted',
  saveDraftInMyDeals: 'Save Draft in "My Deals"',
  realStateLoan: 'Real Estate Loan',
  step: 'step',
  startApprovalStep: 'start approval',

  // Approval
  approvalShare: 'SHARE THE DEAL DETAILS FOR APPROVAL AND INFORMATION',
  preApprovalSubtitle: 'DEFINE CONTACTS FOR PRE-APPROVAL COMMITTEE',
  approvalSubtitle: 'DEFINE CONTACTS FOR CREDIT COMMITTEE',
  addDocuments: 'Add documents',
  readOnly: 'Read Only',
  approval: 'Approval',
  addNewContact: 'ADD NEW APPROVAL OR READ ONLY',
  otherDocuments: 'Other Documents',
  termsheet: 'Termsheet',
  creditPaper: 'Credit Paper',
  comments: 'Comments',
  giveSomeInformation: 'Give Some Information',
  selectPH: 'Select',
  namePH: 'Name',
  decline: 'Decline',
  declineWithFeedback: 'DECLINE WITH FEEDBACK',
  approve: 'APPROVE',
  approvalStepInformation: 'Approval Step Information',
  status: 'Status',
  approvalContacts: 'Approval Contacts',
  preApprovalCommittee: 'Pre-Approval Committee:',
  approvalCommittee: 'Approval Committee:',
  checkDetails: 'Please check the details below and submit your feedback.',
  haveNewLoanLand: 'you have a new LOANLAND Deal for approval.',
  comment: 'Comment',
  approvalReadOnly: 'here are the details of a new LOANLAND Deal (read only).',
  continueTermsheet: 'Continue to Termsheet signing',
  reminderWasSent: ' Reminder was sent on',
  sorryApprovalWithdrawn: 'Sorry, the request for an approval has been withdrawn by LOANLAND.',
  forFurtherInformation: ' For further information, please contact LOANLAND.',
  thankYou: 'Thank you!',
  yourFeedback: 'Your feedback has been submitted.',
  resendEmails: 'An email was sent as a reminder.',
  bannerTopFeedback: 'LOANLAND started the internal Pre-Approval Process and will get back to you as soon as possible. The Deal is inactive until further notice.',
  ifYouStop: 'If you stop, you will mismatch this Deal.',
  continueNegotiation: 'I want to continue with the Deal',
  pleaseApproveGiveFeedback: 'Please approve or give feedback',
  dataUpdated: 'Some documents were updated by counterparty',
  fileDeleted: '{{ file }} document in {{ name }} field was removed by counterparty',
  chatHistory: 'Chat history',
  dealDetails: 'Deal details',
  negotiationHistory: 'Deal history',
  allDoneHere: 'ALL DONE HERE!',
  congratulationsSignedTermsheet: 'CONGRATULATIONS ON A SIGNED TERMSHEET',
  unfortunatelyMismatch: 'UNFORTUNATELY, IT’S A MISMATCH!',
  sorryForNotMaking: 'SORRY FOR NOT MAKING A MATCH HERE',
  delete: 'Delete',
  messages: 'Messages',
  years: 'years',
  yearsUpper: 'Years',
  months: 'Months',

  // Term sheet
  termSheetStep1: 'TERMSHEET SIGNED BY LOANLAND',
  termSheetStep2: 'TERMSHEET SIGNED BY ALL',
  pleaseUpload: 'Please upload',
  uploadedBy: 'uploaded by',

  // Chat
  negotiations: 'Deals',
  chat: 'Chat',
  emptyChat: 'No Chat Selected',
  counterparty: 'Counterparty',
  startChatting: 'Start chatting',
  newInformation: 'New information',
  you: 'You',

  // Transfer
  transferColleague: 'TRANSFER TO A COLLEAGUE',
  transferDescription: 'You are handing over this Deal to a colleague and will not be able to access it again until it is handed back to you.',
  shareDeal: 'SHARE THE DEAL',
  shareDescription: 'Share this Deal and all attached documents with others (read-only). Make sure to share only with those who are within the deal’s confidentiality agreement!',
  transferSuccess: 'Deal transferred',
  transferDealDescription: 'You are handing over this Inquiry to a colleague and will not be able to access it or any corresponding Deals until it is handed back to you.',
  addMore: 'ADD MORE',

  hourChanged: 'Expiry date updated',
  dealDeleted: 'Draft deleted',
  inputsWithErrors: 'Some inputs are incorrect',

  //Inquiries
  inquiry: 'Inquiry',
  inquiryNumber: 'Inquiry Number',
  inquiryName: 'My Inquiry Name',
  inquiryBanner: 'Here are the details about the financing inquiry. If you need further information or would like to get in touch with the borrower, please contact us at <1>clientservices@firstwire.market</1> or +49 221 6777 8850.',
  noAccessTitle: 'we are sorry, the financing request is no longer active on the firstwire marketplace.',
  noAccessContent: 'For questions or feedback, please contact us at clientservices@firstwire.market or +49 221 6777 8850.',
  noAccessFooter: '\nBest regards,\nYour firstwire team',
  noAccessDefault: 'You don\'t have access to this view'
};

export default resourceDE;
