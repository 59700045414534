import routesDictionary from './routesDict';
import asyncComponent from '../HOC/AsyncComponent';

const AsyncHome = asyncComponent(() => import('../views/Home'));
const AsyncLogin = asyncComponent(() => import('../views/Login'));
const AsyncRegister = asyncComponent(() => import('../views/Register'));
const AsyncSetPassword = asyncComponent(() => import('../views/SetPassword'));
const AsyncForgotPassword = asyncComponent(() => import('../views/ForgotPassword'));
const AsyncResetPassword = asyncComponent(() => import('../views/ResetPassword'));
const AsyncMyDeals = asyncComponent(() => import('../views/MyDeals'));
const AsyncCreateRequest = asyncComponent(() => import('../views/CreateRequest'));
const AsyncMarketplace = asyncComponent(() => import('../views/Marketplace'));
const AsyncDealNegotiation = asyncComponent(() => import('../views/DealNegotiation'));
const AsyncMatchSummary = asyncComponent(() => import('../views/MatchNegotiation'));
const AsyncAccountSettings = asyncComponent(() => import('../views/AccountSettings'));
const AsyncLoanLandCreateDeal = asyncComponent(() => import('../views/LoanLandCreateDeal'));
const AsyncLoanLandStartNegotiation = asyncComponent(() => import('../views/LoanLandStartNegotiation'));
const AsyncLoanLandNegotiation = asyncComponent(() => import('../views/DealNegotiationLoanLand'));
const AsyncDownloadPDF = asyncComponent(() => import('../views/DownloadPDF'));
const AsyncAdmin = asyncComponent(() => import('../views/Admin'));
const AsyncApprovalLoanLand = asyncComponent(() => import('../views/ApprovalLoanLand'));
const AsyncApprovalSummary = asyncComponent(() => import('../views/ApprovalSummary'));
const AsyncRevoked = asyncComponent(() => import('../views/Revoked'));
const AsyncThankYou = asyncComponent(() => import('../views/ThankYou'));
const AsyncLoanLandTermSheet = asyncComponent(() => import('../views/TermSheetLoanLand'));
const AsyncMismatch = asyncComponent(() => import('../views/Mismatch'));
const AsyncPassiveInvestorInquiry = asyncComponent(() => import('../views/Inquiries'));
const AsyncNoAccessInquiry = asyncComponent(() => import('../views/Inquiries/NoAccess'));

const routes = [
  {
    path: routesDictionary.noAccessInquiry,
    component: AsyncNoAccessInquiry,
    type: 'persistent',
    title: 'No Access',
  },
  {
    path: routesDictionary.passiveInvestorInquiry,
    component: AsyncPassiveInvestorInquiry,
    type: 'persistent',
    title: 'Inquiry',
  },
  {
    path: routesDictionary.mismatch,
    component: AsyncMismatch,
    type: 'private',
    title: 'Mismatch Deal',
  },
  {
    path: routesDictionary.loanLandTermSheet,
    component: AsyncLoanLandTermSheet,
    type: 'private',
    title: 'Termsheet LoanLand',
  },
  {
    path: routesDictionary.approvalDealLoanLand,
    component: AsyncApprovalLoanLand,
    type: 'private',
    title: 'Approval LoanLand Negotiation',
  },
  {
    path: routesDictionary.approvalDeal,
    component: AsyncApprovalSummary,
    type: 'persistent',
    title: 'Approval Summary',
  },
  {
    path: routesDictionary.thankYou,
    component: AsyncThankYou,
    type: 'persistent',
    title: 'Thank You',
  },
  {
    path: routesDictionary.revoked,
    component: AsyncRevoked,
    type: 'persistent',
    title: 'Deal revoked',
  },
  {
    path: routesDictionary.loanLandNegotiation,
    component: AsyncLoanLandNegotiation,
    type: 'private',
    title: 'Deal LoanLand Negotiation',
  },
  {
    path: routesDictionary.loanLandStartNegotiation,
    component: AsyncLoanLandStartNegotiation,
    type: 'private',
    title: 'Deal Loan Land',
  },
  {
    path: routesDictionary.loanLandCreateDeal,
    component: AsyncLoanLandCreateDeal,
    type: 'private',
    title: 'Create Deal',
  },
  {
    path: routesDictionary.accountSettings,
    component: AsyncAccountSettings,
    type: 'private',
    title: 'Account Settings',
  },
  {
    path: routesDictionary.downloadPDF,
    component: AsyncDownloadPDF,
    type: 'private',
    title: 'Download Documents',
  },
  {
    path: routesDictionary.matchSummary,
    component: AsyncMatchSummary,
    type: 'private',
    title: 'Match Summary',
  },
  {
    path: routesDictionary.dealNegotiation,
    component: AsyncDealNegotiation,
    type: 'private',
    title: 'Deal Negotiation',
  },
  {
    path: routesDictionary.myDeals,
    component: AsyncMyDeals,
    type: 'private',
    title: 'My Deals',
  },
  {
    path: routesDictionary.resetPassword,
    component: AsyncResetPassword,
    type: 'persistent',
    title: 'Reset Password',
  },
  {
    path: routesDictionary.requestDeal,
    component: AsyncCreateRequest,
    type: 'private',
    title: 'Request Deal',
  },
  {
    path: routesDictionary.createRequest,
    component: AsyncCreateRequest,
    type: 'private',
    title: 'Create Request',
  },
  {
    path: routesDictionary.marketplace,
    component: AsyncMarketplace,
    type: 'private',
    title: 'Marketplace',
  },
  {
    path: routesDictionary.login,
    component: AsyncLogin,
    type: 'public',
    title: 'Login',
  },
  {
    path: routesDictionary.register,
    component: AsyncRegister,
    type: 'public',
    title: 'Register',
  },
  {
    path: routesDictionary.setPassword,
    component: AsyncSetPassword,
    type: 'private',
    title: 'Set Password',
  },
  {
    path: routesDictionary.forgotPassword,
    component: AsyncForgotPassword,
    type: 'persistent',
    title: 'Forgot Password',
  },
  {
    path: routesDictionary.admin,
    component: AsyncAdmin,
    type: 'public',
    title: 'Admin',
  },
  {
    path: routesDictionary.home,
    component: AsyncHome,
    type: 'private',
    title: 'Home',
  },
];

export default routes;
