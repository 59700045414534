export const AUTH_TOKEN = '@%!str-2v@ir_io!';
export const REFRESH_TOKEN = 'refreshToken';
/** global: AUTH_TOKEN */
export const API_URL = typeof process.env.REACT_APP_DEV_API_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_API_URL : false;
/** global: API_URL */
export const WS_URL = typeof process.env.REACT_APP_DEV_WS_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_WS_URL : false;
/** global: WS_URL */
export const BACKEND_URL = typeof process.env.REACT_APP_DEV_BACKEND_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_BACKEND_URL : false;
/** global: BACKEND_URL */
export const BACKEND_ADMIN_URL = typeof process.env.REACT_APP_DEV_BACKEND_ADMIN_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_BACKEND_ADMIN_URL : false;
/** global: BACKEND_ADMIN_URL */

export const BACKEND_FRONTEND_LANGUAGES = {
  de: 'DE',
  'en-us': 'EN',
};

export const APP_LANGUAGE = 'language';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY.HH:mm';
export const DATE_TIME_FORMAT_REMINDER = 'DD.MM.YYYY - hh:mm A';

/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const MONTHLY_FORMAT = 'YYYY-MM';
/** global: DATE_FORMAT official format 'DD.MM.YYYY' */
export const TIME_FORMAT_GERMANY = 'HH:mm';
export const FILE_MAX_SIZE = 10;

// File format => https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const FORMAT_FILE_ACCEPTED = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'image/tiff',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.text',
];

export const ERROR_NOTIFICATION = 'error';
export const SUCCESS_NOTIFICATION = 'success';
export const INFO_NOTIFICATION = 'info';
export const WARNING_NOTIFICATION = 'warning';

export const PERCENT_INPUT_TYPE = 'percent';
export const CURRENCY_INPUT_TYPE = 'currency';
export const DAYS_INPUT_TYPE = 'days';
export const YEARS_INPUT_TYPE = 'years';
export const MONTHS_INPUT_TYPE = 'months';

export const clientTypes = {
  1: 'Investor',
  2: 'Borrower',
};
export const OFFER = 1; // INVESTOR
export const BORROWER = 2;

export const REAL_STATE = 0;
export const CORPORATE = 1;
export const MUNICIPALITY = 2;

export const dealType = {
  0: 'realState',
  1: 'corporate',
  2: 'municipality',
};

export const ACTIVE = 0;
export const SUBMIT = 1;
export const MISMATCHED = 2;
export const CLOSED = 3;
export const DRAFT = 4;
export const REQUEST_MATCHED = 5;

export const APPROVAL_1 = 1;
export const APPROVAL_2 = 2;
export const APPROVAL_SUBMIT = 3;
export const PENDING = 1;

export const REQUIRED_SUBMIT = 1;
export const REQUIRED_MATCHING = 2;
export const REQUIRED_LOANLAND = 3;

export const DECLINED_STATE = 2;
export const ACCEPTED_STATE = 1;

export const NUMBER_REGEX = /^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:(\.|,)\d{0,4})?$/;
export const MAX_INT = 1000000000;
export const MAX_BIG_INT = 10000000000000000000;
export const FILES_LOAN_LAND_LIMIT = 5;
export const TIME_ZONE = 'CET';
