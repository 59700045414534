import { gql } from 'apollo-boost';

/**
 * Query to get terms and conditions
 */
export const GET_TERMS = gql`
 query {
    getLastTermsCondition {
      id
      body
      date
    }
}
`;
