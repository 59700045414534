import moment from 'moment';
import 'moment-timezone';
import i18n from 'i18next';
import {
  AUTH_TOKEN, BACKEND_FRONTEND_LANGUAGES,
  CURRENCY_INPUT_TYPE,
  DAYS_INPUT_TYPE,
  MONTHS_INPUT_TYPE,
  PERCENT_INPUT_TYPE,
  REFRESH_TOKEN,
  YEARS_INPUT_TYPE,
} from '../settings/constants';
import { showNotification, showNotificationInfo } from '../components/Notification';
import { GET_TOOLTIPS } from '../graphql/queries/SpeedMatch';
import {
  ReactComponent as FinalizedTermsheetImgDE,
} from '../media/images/mismatch_cloudDE.svg';
import {
  ReactComponent as FinalizedTermsheetImg,
} from '../media/images/mismatch_cloud.svg';

/**
 * Function to get auth token name by tenant domain
 * @function getAuthTokenName
 * @returns {String}
 */
export function getAuthTokenName() {
  return AUTH_TOKEN;
}

/**
 * Function to get auth token name by tenant domain
 * @function getAuthTokenName
 * @returns {String}
 */
export function getRefreshTokenName() {
  return REFRESH_TOKEN;
}

/**
 * Funtion to check if a value is null.
 * @function isNull
 * @param value - Any value that can be null.
 * @returns {Boolean}
 */
export function isNull(value) {
  return value === null;
}

/**
 * Funtion to check if a value is undefined.
 * @function isUndefined
 * @param value - Any value that can be undefined.
 * @returns {Boolean}
 */
export const isUndefined = (value) => typeof value === 'undefined';

/**
 * Function to set login tokens
 * @function setLoginTokens
 * @param accessToken: access token provided by backend
 * @param refreshToken: refresh token provided by backend
 */
export const setLoginTokens = (accessToken, refreshToken) => {
  localStorage.setItem(getAuthTokenName(), accessToken);
  localStorage.setItem(getRefreshTokenName(), refreshToken);
};

/**
 * Function to delete all undefined items in objects
 * @function deleteUndefined
 * @param obj: any object
 */
export const deleteUndefined = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
};

export const snakeToCamelCase = (str) => str.replace(/(\s|\t|_)([a-z])/g, (g) => g[1].toUpperCase());

export const setFormErrors = (form, errors) => {
  if (errors) {
    const errorsFields = errors.map((element) => ({
      name: snakeToCamelCase(element.field),
      errors: element.messages,
    }));
    form.setFields(errorsFields);

    return true;
  }

  return false;
};

export const logout = (setLoginState) => {
  localStorage.removeItem(getAuthTokenName());
  localStorage.removeItem(getRefreshTokenName());
  localStorage.removeItem('firstLogin');
  localStorage.removeItem('terms');
  localStorage.removeItem('rememberMe');
  setLoginState('');
};

export function isInt(n) {
  return n % 1 === 0;
}

export const customSuffix = (type, t) => {
  switch (type) {
    case PERCENT_INPUT_TYPE:
      return '%';
    case CURRENCY_INPUT_TYPE:
      return 'Eur';
    case DAYS_INPUT_TYPE:
      return 'Days';
    case YEARS_INPUT_TYPE:
      return t('yearsUpper');
    case MONTHS_INPUT_TYPE:
      return t('months');
    default:
      return '';
  }
};

export const handleFeedback = (feedback, t) => {
  const title = feedback.type.toLowerCase();

  showNotificationInfo(t(`${title}Notification`), feedback.type.toLowerCase(), feedback.message);
};

export const germanNumberFormat = (num, decimal = 2) => {
  if (!isUndefined(num) && !isNull(num)) {
    const cleanString = parseFloat(num).toFixed(decimal)
      .replace(/\./g, ',');
    const number = cleanString.split(',');
    const numberInteger = number[0];

    let newValue = numberInteger;
    if (!isNaN(parseFloat(numberInteger))) {
      newValue = (numberInteger)
        // .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return `${newValue}${number.length > 1 ? `,${number[1]}` : ''}`;
  }
};

/**
 * Function to format number (1.234.234,50)
 * @param string number to convert
 */
export const formatFloatWithDots = (num) => {
  if (num) {
    const cleanString = num.toString()
      .replace(/\./g, '');

    const number = cleanString.split(',');
    const numberInteger = number[0];

    let newValue = numberInteger;
    if (!isNaN(parseFloat(numberInteger))) {
      newValue = (numberInteger)
        // .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return `${newValue}${number.length > 1 ? `,${number[1]}` : ''}`;
  }
};

export const cleanNumber = (value = '') => {
  if (value === 0 || value) {
    const cleanString = value.toString()
      .replace(/\./g, '')
      .replace(/,/g, '.');

    return isNaN(parseFloat(cleanString)) ? value : parseFloat(cleanString) || 0;
  }
};

export const saveInput = (value) => (cleanNumber(value) === 0 ? 0 : cleanNumber(value) || null);

/**
 * Function to format number (1.234.234)
 * @param string number to convert
 */
export const formatNumberWithDots = (num) => {
  if (num) {
    const cleanString = num.toString()
      .replace(/\./g, '');
    const number = cleanString.split(',');
    const numberInteger = number[0];

    let newValue = numberInteger;
    if (!isNaN(parseInt(numberInteger))) {
      newValue = numberInteger
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return `${newValue}${number.length > 1 ? `,${number[1]}` : ''}`;
  }
};

export function onLinkClick(name) {
  const element = document.getElementsByName(name);

  if (element.length) {
    element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

export function onLinkClickById(id) {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export const format = {
  number: formatNumberWithDots,
  float: formatFloatWithDots,
};

export const removeErrorsForm = (form, name) => {
  form.setFields([{
    name,
    errors: [],
  }]);
};

export const getTooltips = ({ getTooltips }) => {
  const tooltips = {};
  getTooltips.map((tooltip) => {
    tooltips[tooltip.key] = <div dangerouslySetInnerHTML={{ __html: tooltip.content }} />;
  });
  return tooltips;
};

export const getTooltipsValue = (requestType, client, setTooltips) => {
  client.query({
    query: GET_TOOLTIPS,
    fetchPolicy: 'no-cache',
    variables: { requestType },
  }).then(({ data }) => {
    setTooltips(getTooltips(data));
  }).catch((error) => console.log(error));
};

export function timeCET(time, timeFormat) {
  return moment(time).tz('Europe/Berlin').format(timeFormat);
}

export function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function renderByLanguage(firstComponent, secondComponent) {
  return i18n.language === BACKEND_FRONTEND_LANGUAGES.de
    ? firstComponent : secondComponent;
}
