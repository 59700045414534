import { gql } from 'apollo-boost';
import { ChatMessage } from '../fragments';

/**
 * Subscription to get changes in negotiation IOI
 */
export const CHANGE_IOI_FIELDS = gql`
  subscription ($ioiId: ID!) {
    changeFieldSubscription(ioiId: $ioiId) {
      fieldChanges {
        id
        status
        fieldCamelCase
        value
        lastValue
        isOwnNegotiation
      }
    }
  }
`;

/**
 * Subscription to get changes in negotiation IOI
 */
export const CHANGE_IOI_FILES = gql`
  subscription($ioiId: ID!) {
    changeFileSubscription(ioiId: $ioiId) {
      deletedFiles
      ioiFile {
        id
        name
        fileName
        fieldCamelCase
        link
        confirmed
        isOwnNegotiation
      }
    }
  }
`;

/**
 * Subscription to get changes in my deals
 */
export const MY_DEALS_SUBSCRIPTION = gql`
  subscription {
    ioiChangeSubscription {
      ioi {
        id
        readNew
        readChange
      }
    }
  }
`;

/**
 * Subscription to get changes with negotiation state
 */
export const IOI_STATUS = gql`
  subscription($ioiId: ID!) {
    ioiStatusSubscription(ioiId: $ioiId) {
      ioi {
        id
        status
        statusDisplay
        isMatchRequestOwner
        speedMatchStatus
      }
    }
  }
`;

/**
 * Subscription to get response of contacts
 */
export const APPROVAL_NOTIFICATION = gql`
  subscription($ioiId: ID!) {
    approvalSubscription(ioiId: $ioiId) {
      newApprovalStatus
      newApprovalStatusDisplay
      contact {
        id
        isApprove
        notification {
          title
          message
        }
      }
    }
  }
`;

/**
 * Subscription to get files uploaded in term sheet
 */
export const TERM_SHEET_FILES = gql`
  subscription ($ioiId: ID!){
  termSheetSignedSubscription(ioiId: $ioiId) {
    termSheet {
      id
      fileName
      termSheetTypeDisplay
      termSheetType
      accepted
      link
      uploadedAt
      uploadedBy {
        id
        firstName
        lastName
        client {
          id
          companyName
        }
      }
    }
    isNew
    isAccepted
  }
}
`;

/**
 * Subscription to connect chat
 */
export const CHAT_GENERAL = gql`
  subscription {
    chatGeneralSubscription {
      userConnected {
        userId
        iois
        isOnline
      }
      chatMessage {
        id
        counterpartyActiveChat
        message
        chatRoom {
          id 
          ioi {
            id
            request {
              id
            }
          }
          pendingMessages {
            ...ChatMessage
          }
          chatmessageSet {
            ...ChatMessage
          }
        }
      }
    }
  }
  
  ${ChatMessage}
`;
