import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './styles/overwrite.scss';
import './styles/base.scss';
import './styles/utils.scss';
import './styles/responsive.scss';
import Base from './wrappers/Base';
import { ChatProvider } from './wrappers/Context/index';

const App = () => (
  <Router data-test="component-router">
    <ChatProvider>
      <Base />
    </ChatProvider>
  </Router>
);

export default App;
