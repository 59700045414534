import { CHAT_INBOX_ROOMS } from '../../graphql/queries/Chat';

export function getAllChats(client, setPendingItem, setChats) {
  client.query({
    query: CHAT_INBOX_ROOMS,
  }).then(({ data }) => {
    if (data.getChatRooms) {
      const filter = data.getChatRooms.filter((item) => item.pendingMessages.length);

      setPendingItem(filter.length);
      setChats(data.getChatRooms);
    }
  });
}

export function updateListMessages(client, data, chats, setPendingItem, setChats) {
  //  Update list of messages
  if (data && data.chatMessage) {
    const { chatRoom } = data.chatMessage;
    const chatsNew = [];
    let chatInbox = {};
    let checkPending = false;

    chats.forEach((chat) => {
      if (chat && chat.id === chatRoom.id) {
        chatInbox = { ...chat };
        chatInbox.new = true;
        chatInbox.chatmessageSet = chatRoom.chatmessageSet;
        checkPending = checkPending || chatRoom.pendingMessages.length;
      } else {
        chatsNew.push(chat);
      }
    });

    setPendingItem(checkPending);
    if (!Object.keys(chatInbox).length) {
      getAllChats(client, setPendingItem, setChats);
    } else {
      setChats([chatInbox, ...chatsNew]);
    }
  }
}

export function getInboxDescription(t, userInformation, item) {
  return `${item.chatmessageSet.length ? `${
    item.chatmessageSet[0].sender.client.id === userInformation.client.id ? t('you') : t('counterparty')
  }:  ${item.chatmessageSet[0].message}` : t('startChatting')}`;
}
