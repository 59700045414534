import React from 'react';
import { ApolloProvider, concat } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { split } from 'apollo-link';
import { InMemoryCache } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import {
  API_URL, APP_LANGUAGE, AUTH_TOKEN, WS_URL,
} from '../../settings/constants';
import { refreshTokenLink } from './refresh';
import LayoutView from '../Layout';

const cache = new InMemoryCache();

function customFetch(url, opts = {}) {
  opts.headers.authorization = localStorage.getItem(AUTH_TOKEN)
    ? `JWT ${localStorage.getItem(AUTH_TOKEN)}` : '';
  opts.headers['Accept-Language'] = localStorage.getItem(APP_LANGUAGE);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(opts.method || 'get', url);

    for (const k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);

    xhr.onload = (e) => resolve({
      ok: true,
      text: () => Promise.resolve(e.target.responseText),
      json: () => Promise.resolve(JSON.parse(e.target.responseText)),
    });

    xhr.onerror = reject;

    xhr.send(opts.body);
  });
}

// const wsLink = new WebSocketLink({
//   uri: WS_URL,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       Authorization: localStorage.getItem(AUTH_TOKEN)
//         ? `JWT ${localStorage.getItem(AUTH_TOKEN)}` : '',
//     },
//   },
// });

const httpLink = (setLoginState) => concat(
  refreshTokenLink(setLoginState),
  createUploadLink({
    uri: API_URL,
    fetch: typeof window === 'undefined' ? global.fetch : customFetch,
  }),
);

const splitLink = (setLoginState, wsLink) => split(

  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'

    );
  },
  wsLink,
  httpLink(setLoginState),
);

const client = (setLoginState) => {
  // TODO: Improve wsLink connection
  const wsLink = new WebSocketLink({
    uri: WS_URL,
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: localStorage.getItem(AUTH_TOKEN)
          ? `JWT ${localStorage.getItem(AUTH_TOKEN)}` : '',
      },
    },
  });

  return new ApolloClient({
    link: splitLink(setLoginState, wsLink),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'no-cache',
      },
      mutate: {
        errorPolicy: 'no-cache',
      },
    },
  });
};

const Apollo = (props) => (
  <ApolloProvider client={client(props.setLoginState)}>
    <LayoutView {...props} />
  </ApolloProvider>
);

export default Apollo;
