import React, { useEffect, useState } from 'react';
import { Input, Row, Spin } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useApolloClient, useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CHAT_INBOX_ROOM } from '../../../graphql/queries/Chat';
import { CHAT_ROOM, MARK_READ_MESSAGE, SEND_CHAT } from '../../../graphql/mutations/Chat';
import { DATE_FORMAT } from '../../../settings/constants';
import { isNull, onLinkClickById, timeCET } from '../../../utils/tools';
import './styles.scss';

function Inbox({
  chat, ioiId, userInformation, chats, setChats,
}) {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [inbox, setInbox] = useState([]);
  const [readInbox] = useState({});

  const { data: newChats = {} } = useSubscription(CHAT_ROOM, {
    variables: { ioiId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isNull(chat)) {
      setLoading(true);

      client.query({
        query: CHAT_INBOX_ROOM,
        variables: {
          ioiId,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        const { chatmessageSet } = data.getChatRoom;

        chatmessageSet.sort((a, b) => (parseInt(a.id, 10) > parseInt(b.id, 10) ? 1 : -1));
        setUserData(data.getChatRoom);
        setInbox(chatmessageSet);

        if (chatmessageSet.length) {
          setTimeout(() => onLinkClickById(chatmessageSet[chatmessageSet.length - 1].id), 150);
        }
        setLoading(false);
      }).catch((error) => console.log(error));
    } else {
      setLoading(false);
    }
  }, [chat]);

  useEffect(() => {
    if (newChats.chatRoomSubscription) {
      const { chatMessage } = newChats.chatRoomSubscription;

      if (chatMessage) {
        const chatmessageSet = [...inbox, chatMessage];

        chatmessageSet.sort((a, b) => (parseInt(a.id, 10) > parseInt(b.id, 10) ? 1 : -1));

        setInbox(chatmessageSet);
        setTimeout(() => onLinkClickById(chatMessage.id), 250);
      }

      setLoading(false);
    }
  }, [newChats]);

  function handleSendMessage(value) {
    client.mutate({
      mutation: SEND_CHAT,
      variables: {
        ioiId: chat.ioi,
        message: value,
      },
    }).then(({ data }) => {
      const { success, messageId } = data.sendChatMessage;

      if (success) {
        const newMessage = {
          id: messageId,
          message: value,
          readByReceiver: false,
          sender: {
            id: userInformation.id,
            client: {
              id: userInformation.client.id,
            },
            firstName: userInformation.firstName,
            lastName: userInformation.lastName,
          },
          sentAt: new Date(),
        };

        setTimeout(() => onLinkClickById(messageId), 150);

        setInbox([...inbox, newMessage]);
        const chatsNew = [];
        let chatInbox;

        chats.forEach((item) => {
          if (item.id === userData.id) {
            chatInbox = { ...item };
            chatInbox.chatmessageSet = [newMessage];
          } else {
            chatsNew.push(item);
          }
        });

        setChats([chatInbox, ...chatsNew]);
      }
    }).catch((error) => console.log(error));
  }

  function handleReadMessage(messageId) {
    client.mutate({
      mutation: MARK_READ_MESSAGE,
      variables: {
        messageId,
      },
    }).then(({ data }) => {
      const { success } = data.markMessageRead;

      if (success) {
        readInbox[messageId] = true;
      }
    }).catch((error) => console.log(error));
  }

  if (loading) {
    return (
      <div className="chats-inbox">
        <div className="input-chat-container-empty">
          <Row className="w-100 h-100" justify="center" align="middle">
            <Spin size="large" />
          </Row>
        </div>
      </div>
    );
  }

  return (
    <div className="chats-inbox">
      <div className="input-chat-container">
        {chat && Object.keys(userData).length ? (
          <div>
            <div className="user-data">
              <div>{`${t('deal')} #${chat.deal} - ${chat.ioi}`}</div>

            </div>
            {inbox.map((item) => {
              const checkOwner = item.sender.client.id !== userInformation.client.id;

              if (checkOwner && item.id && !item.readByReceiver) {
                if (!readInbox[item.id]) {
                  handleReadMessage(item.id, item);
                  readInbox[item.id] = true;
                }
              }

              return (
                <div key={`inbox-${item.id}`} className={`pt-1 ${(checkOwner) ? 'inbox-start' : 'inbox-end'}`}>
                  <div
                    id={item.id}
                    className={`p-1 inbox-item ${checkOwner ? 'inbox-right' : 'inbox-left'}`}
                  >
                    {item.message}
                    {/* {item.readByReceiver ? <CheckCircleFilled className="check-message-icon" /> */}
                    {/*  : <CheckCircleOutlined className="check-message-icon" />} */}
                  </div>
                  <div className="pb-1 pl-2 pr-2 font-xsmall">
                    {`${timeCET(item.sentAt, `${DATE_FORMAT} - HH:mm`)} CET`}
                  </div>
                </div>
              );
            })}
          </div>
        )
          : (
            <div className="empty-chat">
              No Chat Selected
            </div>
          )}
      </div>
      <div className="w-100 p-1 input-chat-inbox">
        <Input
          value={message}
          onChange={({ target }) => {
            setMessage(target.value);
          }}
          onPressEnter={({ target }) => {
            handleSendMessage(message);
            setMessage('');
          }}
        />
        <span onClick={() => {
          handleSendMessage(message);
          setMessage('');
        }}
        >
          <SendOutlined
            className="ml-1 send-message-icon"
          />
        </span>
      </div>
    </div>
  );
}

Inbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  chat: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userInformation: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  chats: PropTypes.array.isRequired,
  setChats: PropTypes.func.isRequired,
};

Inbox.defaultProps = {};

export default Inbox;
