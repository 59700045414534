import { gql } from 'apollo-boost';
import { ChatMessage } from '../../fragments';

/**
 * Subscription to get all chats
 */
export const CHAT_ROOM = gql`
  subscription ($ioiId: ID!){
    chatRoomSubscription(ioiId: $ioiId) {
      counterpartyOnline
      chatMessage {
        id
        chatRoom {
          id
          ioi {
            id
            request {
              id
            }
          }
          chatmessageSet {
            ...ChatMessage
          }
        }
        sender {
          id
          email
          client {
            id
          }
        }
        message
      }
    }
  }
  ${ChatMessage}
`;

/**
 * Mutation to send chat to counterparty
 */
export const SEND_CHAT = gql`
  mutation ($ioiId: ID!, $message: String!) {
    sendChatMessage(ioiId: $ioiId, message: $message) {
      success
      messageId
    }
  }
`;

/**
 * Mutation to mark message as read
 */
export const MARK_READ_MESSAGE = gql`
  mutation ($messageId: ID!) {
    markMessageRead(messageId: $messageId) {
      success
      fieldErrors {
        field
        messages
      }
    }
  }
`;
