/*
  Deutsch Resource
 */

const resourceDE = {
  // Header
  yourMarket: 'Your Market. Your Community.',
  accountSettings: 'Kontoeinstellungen',
  logOut: 'Abmelden',

  registerDescription: 'Sie haben noch kein Benutzerkonto?',
  registerBtn: 'Jetzt registrieren',
  demoTitle: 'Testen Sie firstwire Demo!',
  andMore: '... und vieles mehr.',
  accessOver: 'Blockchain-Technologie live erleben',
  crossIndustry: 'Vielseitige Features nutzen',
  flexibleRequest: 'Deals in Echtzeit verhandeln',
  emailAddress: 'E-Mail-Adresse',
  emailAddressPH: 'Ihre E-Mail-Adresse',
  password: 'Passwort',
  passwordPH: 'Ihr Passwort',
  welcomeAgain: 'Willkommen zurück!',
  forgotPassword: '<0>Passwort vergessen?<0>',
  loginBtn: 'Anmelden',
  rememberAccount: 'Auf diesem Gerät angemeldet bleiben. Wenn dieses Feld aktiviert ist, setzen wir Cookies auf dem Gerät.',
  registerTitle: 'Hier registrieren und loslegen!',
  gender: 'Anrede',
  genderPH: 'Wählen Sie Ihre Anrede',
  firstName: 'Vorname',
  firstNamePH: 'Geben Sie Ihren Vornamen ein',
  lastName: 'Nachname',
  lastNamePH: 'Geben Sie Ihren Nachnamen ein',
  companyName: 'Firmenname',
  companyNamePH: 'Geben Sie Ihren Firmennamen ein',
  emailRegisterPH: 'Geben Sie Ihre E-Mail-Adresse ein',
  cellphone: 'Telefonnummer',
  cellphonePH: 'Geben Sie Ihre Telefonnummer ein',
  termsAndConditions: '<0>Ich erteile die Einwilligung zur Erhebung, Verarbeitung und Nutzung meiner Daten gemäß Datenschutzerklärung. Die</0> <1>Datenschutzerklärung</1><0> sowie die </0><2>Nutzungsbedingungen</2><0> habe ich gelesen und akzeptiere diese.</0>',
  registerFormBtn: 'Registrieren',
  returnBtn: 'Zurück',
  acceptBtn: 'Akzeptieren',
  termsAndConditionsTitle: 'Bedingungen und Konditionen',
  alreadyAccount: 'Sie haben bereits ein Konto? <0>Hier anmelden.</0>',
  phoneErrorFormat: 'Bitte nur gültiges Format eingeben',
  emailPH: 'Geben Sie Ihre E-Mail-Adresse ein',
  forgotPasswordTitle: 'Passwort zurücksetzen',
  forgotPasswordDescription: 'Geben Sie hier die E-Mail-Adresse an, mit der Sie sich registriert haben. Wir senden Ihnen eine E-Mail mit einen Link zu, mit dem Sie Ihr Passwort zurücksetzen können.',
  sendBtn: 'Absenden',
  goBackBtn: 'Zurück',
  loginFeedbackError: 'Bitte geben Sie eine gültige E-Mail-Adresse und Ihr Passwort ein. Achten Sie auf Groß- und Kleinschreibung.',
  goMyDealsBtn: 'My deals',
  setPasswordTitle: 'Passwort festlegen',
  youEmailAddressPH: 'Ihre E-Mail-Adresse',
  sendMailDescription: 'Bitte überprüfe Sie Ihr Postfach, und klicken Sie in der firstwire E-Mail auf den Link zum Zurücksetzen des Passwortes',
  sendMailTitle: 'Wir haben Ihnen eine E-Mail gesendet!',
  goHomeBtn: 'Zurück zur Startseite',
  setPasswordDescription: 'Bitte geben Sie Ihr Passwort ein',
  setPasswordOK: 'Ihr Passwort wurde erfolgreich geändert!',
  resetPasswordTitle: 'Passwort zurücksetzen',
  resetPasswordDescription: 'Ein neues Passwort einführen',
  passwordChanged: 'Das Passwort hat sich geändert',
  invalidLinkResetPasswordTitle: 'Ungültiger Link!',
  operationSuccess: 'Änderungen erfolgreich!',
  or: 'oder',
  additionalInfo: 'Zusätzliche Informationen',
  additionalInfoPH: '',
  selectFile: 'DATEI AUSWÄHLEN',
  filePH: 'File',
  newAccount: 'Sie haben noch kein Konto? <0>Neu registrieren.<0>',
  successRegisterAccount: 'Danke für die Registrierung!',
  ok: 'OK',
  warningNotification: 'Warning',
  errorNotification: 'Error',
  successNotification: 'Success',
  partOf: 'Sie gehören zu',
  specifyYourDeal: 'MEINE ANFRAGE',
  letsSpecifyYourDeal: 'Beschreiben Sie Ihre Anfrage',
  dealNumber: 'Anfrage Nummer',
  dealType: 'Deal Typ',
  saveInMyDeals: 'Entwurf in "My Deals" speichern',
  mandatory: 'Pflichtfeld',
  dealName: 'Mein Anfrage Name',
  collapseAll: 'ALLE AUSBLENDEN',
  expandAll: 'Alle erweitern',
  onlyVisible: '(nur für Sie sichtbar)',
  liveMarket: 'AKTUELLES Marktinteresse',
  counterparties: 'Investoren',
  volume: 'Volumen',
  numberTenantTooltip: 'Einige weitere Informationen<0 />können hier angezeigt werden.',
  liveMarketInterestTooltip: 'Hier sehen Sie, wie viele potenzielle Kreditgeber zu Ihrer Finanzierungsanfrage passen und welches kumulierte Kreditvolumen diese zur Verfügung stellen können.',

  // Errors
  emailFormat: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  requiredField: 'Dieses Feld ist notwendig.',
  passwordRuleSize: 'Mindestens acht Zeichen lang sein.',
  atLeastOneDigit: 'Mindestens eine Zahl enthalten.',
  atLeastOneSmallCase: 'Mindestens einen Kleinbuchstaben enthalten.',
  atLeastOneUpperCase: 'Mindestens einen Großbuchstaben enthalten.',
  atLeastOneSpecialCase: 'Enthält mindestens ein Sonderzeichen.',
  somethingWentWrong: 'Something went wrong!',
  onlyPositiveNumber: 'Only positive numbers',
  maxDigits: 'Bitte nicht mehr als {{ digits }} Ziffern eintragen',
  maxNumber: 'Dieser Wert muss kleiner oder gleich {{ max_number }} sein.',
  yearDigits: 'Bitte eine vierstellige Zahl eingeben',

  // Error fields
  visibleField: 'Dieses Feld muss sichtbar sein.',
  fillOutField: 'Bitte dieses Feld ausfüllen.',
  selectOneOption: 'Bitte nur einen Wert auswählen.',
  noDecimals: 'Bitte keine Dezimalstellen eintragen.',
  positiveNumber: 'Bitte eine Zahl > 0 eintragen.',
  floatNumber: 'Bitte nicht mehr als 2 Dezimalstellen eintragen.',
  floatNumberDigits: 'Bitte nicht mehr als {{ max_decimals }} Dezimalstellen eintragen.',
  enterNumber: 'Bitte eine Zahl eingeben.',
  atLeastCounterparty: 'Bitte lassen Sie mindestens eine Kategorie zu.',
  dateFormat: 'Bitte ein Datum als TT.MM.JJJJ eintragen.',
  percentFormat: 'Bitte geben Sie eine gültige Nummer ein.',
  validNumber: 'Bitte geben Sie eine gültige Nummer ein',
  invalidRange: 'Ungültige Angabe der Spanne.',
  atLeastOneDisabled: 'Bitte lassen Sie mindestens eine Kategorie zu.',
  greaterThanEqualOne: 'Bitte eine Zahl >= 1 eintragen.',
  greaterThanEqualZero: 'Bitte eine Zahl >= 0 eintragen.',
  fileError: 'Ungültiges Dateiformat. Bitte .doc, .docx., .jpeg, .pdf, .png, .ppt, .pptx, .tiff, .xls oder .xlsx verwenden. Maximale Dateigröße: 10 MB.',

  // Sections
  dealParticipants: 'Deal Teilnehmer',
  objectInformation: 'Object Information',
  assetInformation: 'Informationen zum Objekt',
  financingRequest: 'Finanzierungsdetails',
  documentsNotes: 'Dokumente und Notizen',
  OnlyNotes: 'Notizen',
  privateInformation: 'Private Informationen',
  issuerFinancials: 'Informationen zum Emittenten',
  dealSummary: 'ZUSAMMENFASSUNG',
  dealDocuments: 'Deal Dokumente',
  continueNegotiating: 'Verhandlung fortführen',
  submitMatch: 'Match anfragen',

  // Home
  realState: 'Immobilien',
  securedDebt: 'Besicherte Finanzierung',
  unsecuredDebt: 'Unbesicherte Finanzierung',
  corporate: 'Unternehmen',
  municipality: 'Kommunen / Öffentl. Hand',
  firstwireTemplate: 'firstwire Template',
  letsGo: 'Los geht\'s',
  specifyDeal: 'Bitte wählen Sie eine Vorlage aus und starten Sie Ihre Anfrage.',
  whatCreate: 'Was für eine Anfrage möchten Sie erstellen?',
  dealReady: 'Ihre Anfrage ist bereit!',
  pleasePublish: 'Senden Sie Ihre Anfrage an den Marktplatz und finden Sie ein Match. Die Anfrage wird automatisch zu "My Deals" hinzugefügt.',
  submitToMarket: 'An Marktplatz senden',
  refineDeal: 'Anfrage anpassen',
  findInvestor: 'FINANZIERUNG AUFNEHMEN',
  create: 'Create',
  searchDeals: 'INVESTIEREN',
  createDeal: 'ANFRAGE STARTEN',
  viewDeals: 'DEALS SUCHEN',
  explore: 'Explore',
  myDealsCard: 'Aktivitäten anzeigen',
  myDealsText: 'Bearbeiten Sie Ihre Anfragen und schließen Deals ab.',
  showMe: 'JETZT ANZEIGEN',
  dealTotalHome: 'Derzeit {{ number }} Deals, die darauf warten, abgeglichen zu werden',
  exploreMarketplace: 'Erkunden Sie den Marktplatz und finden Sie Ihre passende Investition.',
  createTextHome: 'Zugang zu 350 Investoren mit > EUR {{investableCapital}} an verfügbarem Finanzierungsvolumen.',
  qualifyDeal: 'SIE QUALIFIZIEREN SICH FÜR EIN SPEED MATCH!',
  speedMatchOption: 'Mit der Option Speed Match wird der Prozess ausschließlich mit LOANLAND gestartet. Dieser Weg ist schneller als der normale Prozess.',
  afterFilling: 'Bitte geben Sie im folgenden Schritt weitere Informationen an und erhalten Sie innerhalb der',
  getFeedback: 'nächsten 2 Arbeitstage eine Rückmeldung.',
  ifNotConnect: 'Wenn Sie sich nicht mit LOANLAND verbinden möchten, können Sie Ihre Anfrage trotzdem an den Marktplatz senden.',
  startSpeedMatch: 'SPEED MATCH PROZESS STARTEN',
  moreDetails: 'Sie möchten mehr über LOANLAND und den Speed Match Prozess erfahren?',
  loanLandDetailsTitle: 'LOANLAND ist DER Anbieter von schnellen und zuverlässigen Immobilienkrediten.',
  loanLandDetailsPrimary: 'LOANLAND ist ein Real Estate Private Debt Spezialist mit Sitz in Frankfurt und agiert als Investmentmanager sowohl für Kreditfonds als auch für Einzelmandate. LOANLAND entwickelt maßgeschneiderte Finanzierungslösungen und bietet u.a. Whole Loans mit attraktiven LTVs an.',
  loanLandDetailsSecondary: 'LOANLAND und firstwire bieten im Zuge einer exklusiven Kooperation den Speed Match-Prozess an, einen technologiebasierten „Fast track“ für Kreditgesuche, die den Investitionskriterien von LOANLAND entsprechen. Speed Match ermöglicht es, in einer vollständig digitalisierten Prozesslandschaft alle Schritte von der ersten Kreditanfrage bis zur Verhandlung und Unterzeichnung des Termsheets abzubilden.',
  loanLandDetailsLink: 'WEBSEITE ANSCHAUEN',
  newsSlogan: 'NEUIGKEITEN <0>@firstwire</0>',
  newsDescription: 'Hier finden Sie Updates und Neuigkeiten.',

  // Form
  sponsorName: 'Sponsor Name',
  sponsorType: 'Sponsor Typ',
  sponsorCountry: 'Sponsor Staat',
  borrowerName: 'Kreditnehmer Name',
  borrowerCountry: 'Kreditnehmer Staat',
  borrowerSector: 'Kreditnehmer Sektor',
  issuerName: 'Emittentenname',
  issuerSector: 'Emittentensektor',
  issuerCountry: 'Emittenten Staat',
  investorName: 'Investorenname',
  investorenType: 'Investoren Typ',
  investorType: 'Investoren Typ',
  investorCountry: 'Investoren Staat',
  issuerCreditQualityRating: 'Bonität & Rating des Emittenten',
  annualSales: 'Jahresumsatz',
  leverageRatio: 'Verschuldungsgrad',
  netDebt: 'Nettoverschuldungsgrad',
  totalDebt: 'Gesamtschuld / EBITDA',
  assetClass: 'Nutzungsart',
  location: 'Lage',
  country: 'Land',
  address: 'Adresse',
  numberObjects: 'Anzahl der Objekte',
  lettableSpace: 'Mietfläche',
  rentalUnits: 'Vermietbare Einheiten',
  numberTenants: 'Anzahl der Mieter',
  occupancyRate: '(Vor-) Vermietungsquote',
  grossRentalIncome: 'Netto-Kaltmiete (IST) p.a.',
  grossRentalIncomeERV: '(Erwartete) Netto-Kaltmiete (SOLL) p.a.',
  netOperatingIncome: 'Nettobetriebsergebnis (IST) p.a.',
  wault: 'WAULT',
  buildingCondition: 'Gebäudezustand & ESG',
  yearConstruction: 'Baujahr',
  tenure: 'Eigentumsart',
  financingPurpose: 'Finanzierungsanlass',
  financingRank: 'Finanzierungstyp',
  financingAmount: 'Kreditvolumen',
  financingAmountStart: '',
  financingAmountEnd: '',
  currency: 'Währung',
  loanTerm: 'Laufzeit',
  loanTermStart: '',
  loanTermEnd: '',
  fixedInterestPeriod: 'Zinsbindung',
  marketValue: 'Marktwert (Schätzung)',
  totalInvestmentCosts: 'Gesamtinvestitionskosten',
  ltc: 'Minimum LTC',
  ltv: 'Minimum LTV',
  debtYield: 'Schuldentragfähigkeit',
  maturity: 'Laufzeit',
  typeLoan: 'Finanzierungsinstrument',
  typeDebtInstrument: 'Finanzierungsinstrument',
  collateral: 'Sicherheiten & Financial Covenants',
  spread: 'Marge',
  spreadStart: '',
  spreadEnd: '',
  coupon: 'Zinssatz',
  couponStart: '',
  couponEnd: '',
  cleanPrice: 'Clean Price',
  amortization: 'Tilgung p.a.',
  repayment: 'Rückzahlung',
  couponFeatures: 'Kuponausgestaltung',
  couponFrequency: 'Zinsrhythmus',
  couponDaycount: 'Zinsberechnungsmethode',
  couponConvention: 'Zins Arbeitstagskonvention',
  makewhole: 'Makewhole',
  upfrontFee: 'Upfront Fee',
  exitFee: 'Exit Fee',
  deadline: 'Ablaufdatum',
  additionalInformation: 'Weitere Informationen',
  documents: 'Dokumente',
  investorPresentation: 'Investor presentation',
  documentation: 'Documentation',
  annualReport: 'Annual Report',
  other: 'Weitere Dokumente',
  transactionDocuments: 'Transaktionsdokumente',
  investorRelationsDocuments: 'Investor Relations Dokumente',
  KYCAMLDocuments: 'KYC/AML Dokumente',
  budgetRelatedDocuments: 'Haushaltsdokumente',
  assetRelatedDocuments: 'Objektbezogene Dokumente',
  sponsorRelatedDocuments: 'Sponsorbezogene Dokumente',

  blacklistedCounterparties: 'Blacklisted Gegenparteien',
  blacklistedCounterpartyCategories: 'Blacklisted Kategorien',
  saveSuccess: 'Entwurf gespeichert',
  submitSuccess: 'Anfrage veröffentlicht',
  parties: 'Parties',

  // Multiselect
  passLimitMessage: 'Es gibt mehr als {{ limit }} Ergebisse. Versuchen Sie, Ihre Suche zu präzisieren.',
  itemsSelected: '{{ items }} Gegenparteien ausgewählt',

  // My Deals
  myDeals: 'My Deals',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  active: 'Aktiv',
  matched: 'Matched',
  mismatched: 'Mismatched',
  closed: 'Beendet',
  failed: 'Erfolglos',
  allNegotiations: 'Alle Deals',
  onMarketplace: 'On Marketplace',
  offMarketplace: 'Off Marketplace',
  draft: 'Entwurf',
  negotiationForInv: 'Deal',
  negotiationsForInv: 'Deals',
  dealNameTitle: 'Anfrage Name',
  debtTypeTitle: 'Debt Type',
  matchSummary: 'Match übersicht',
  moreInfo: 'MEHR ERFAHREN',
  negotiation: 'Verhandlung',
  sqm: 'qm',
  pleaseSelect: 'Bitte auswählen',
  search: 'Bitte eingeben',

  // Deals
  findDeal: 'Finden Sie Ihren passenden Deal',
  mainCategories: 'DEAL KATEGORIE',
  pleaseSpecifyFilter: 'Weitere Filter anwenden',
  resetFilter: 'Filter zurücksetzen',
  lookingForInvest: 'Sie möchten investieren?',
  lookingForFunding: 'Sie suchen eine Finanzierung?',
  showResults: 'Ergebnisse anzeigen',
  borrowerType: 'Kreditnehmertyp',
  dealId: 'Deal ID',
  results: 'Ergebnisse',
  withdrawMarketplace: 'Vom Marktplatz nehmen',
  withdrawMarketplaceSuccess: 'Deal vom Marktplatz genommen',
  share: 'Teilen',
  transfer: 'Transferieren',

  // Account Settings
  generalInfo: 'Allgemeine Informationen',
  emailNotifications: 'EMAIL-Benachrichtigungen',
  permissionGranted: 'NUTZERRECHTE',
  company: 'Unternehmen',
  email: 'E-Mail',
  telephone: 'Telefon',
  cellphoneSetting: 'Mobiltelefon',
  changePasswordSetting: 'Um Ihr Passwort zu ändern, bitte',
  clickHere: 'Klicken Sie hier',
  save: 'Speichern',
  myCellphone: 'Meine Handynummer',
  newIOI: 'Auf neue IOI',
  matchOptions: 'Kann Übereinstimmungsaktionen durchführen',
  missMatchOptions: 'Kann nicht übereinstimmen',
  termsAccepted: 'Hier können Sie das aktuelle und von Ihnen akzeptierte <0>Regelwerk</0> nachlesen',
  ioiReceivedEmail: 'Neuer Deal',
  matchRequestEmail: 'Match Anfrage',
  matchMismatchEmail: 'Match oder Mismatch',
  dailyEmail: 'Tägliche Übersicht',
  foldAll: 'Alle falten',
  startNegotiation: 'DEAL STARTEN',
  deal: 'Deal',

  // Negotiation
  visible: 'sichtbar',
  hidden: 'versteckt',
  showHistory: 'VERLAUF ANZEIGEN',
  mandatoryMatching: 'Pflichtfeld fürs Matching',
  stopNegotiation: 'STOP DEAL',
  matchBtn: 'MATCH',
  onlyOneSelected: 'Bitte wählen Sie nur eine Option aus.',
  pleaseFillIn: 'Bitte ausfüllen',
  requestVisible: 'IDENTITÄT ANFRAGEN',
  notShared: 'Nicht freigegeben',
  stopRequest: 'ANFRAGE ABBRECHEN',
  requestVisibility: 'Identität anfragen',
  feedbackPending: 'Feedback ausstehend',
  submittedCounterparty: 'Die Information wurde an Ihre Gegenpartei gesendet',
  feedbackCounterparty: 'Ihr Feedback wurde an die Gegenpartei gesendet',
  negotiationStopped: 'Deal gestoppt',
  openNegotiationMessage: 'Neue Informationen',
  counterpartyName: 'Name der Gegenpartei',
  counterpartyCategory: 'Kategorie der Gegenpartei',
  acceptedNegotiation: 'Wert wurde akzeptiert',
  declinedNegotiation: 'Wert wurde abgelehnt',
  pleaseAddNewValue: 'Bitte neuen Wert eingeben',
  pleaseFillMandatory: 'Für ein Match müssen alle Pflichtfelder ausgefüllt sein.',
  sendReminderOK: 'Die Erinnerung wurde erfolgreich gesendet.',
  negotiationWithDraw: 'Sie wurden erfolgreich aus der Verhandlung zurückgezogen.',
  negotiationMatch: 'Übereinstimmung',
  matchRequestWasSubmitted: 'Match Anfrage wurde gesendet',
  viewSummary: 'Zusammenfassung anzeigen',
  confirmation: 'Bestätigung',
  pending: 'Ausstehend',
  matchEnquiry: 'Match Anfrage',
  sendReminder: 'Erinnerung senden',
  withDrawnMessage: 'Match Anfrage zurückziehen',
  haveMatch: 'Glückwunsch, Sie haben ein Match.',
  itsMatch: 'ES IST EIN MATCH!',
  goToLobby: 'Zurück zur Lobby',
  negotiationDeclined: 'Deal abgelehnt',
  yourMatchRequest: 'Ihre Match Anfrage wurde gesendet',
  confirmationFeedback: 'Ihre Gegenpartei prüft die Match - Anfrage. Wir informieren Sie über Neuigkeiten.',
  goBackToLobby: 'Zurück zur Lobby',
  areYouSure: 'SIND SIE SICHER?',
  areYouSureYouWantMissMatch: 'Wollen Sie wirklich ein Mismatch?',
  withdrawYourMatchRequest: 'Möchten Sie Ihre Match Anfrage zurückziehen?',
  withdrawAndContinueNegotiation: 'Match Anfrage zurückziehen und Verhandlung fortsetzen',
  withdrawAndStopNegotiation: 'Match Anfrage zurückziehen und Verhandlung abbrechen',

  //  LoanLand speed match
  loanLandMessage: 'Um innerhalb von ZWEI Werktagen eine Rückmeldung zu erhalten, übermitteln Sie bitte die angeforderten Informationen (Felder und Vorlagen).',
  sourceFunds: 'Mittelherkunft',
  usesFunds: 'Mittelverwendung',
  equityVolume: 'Eigenmittel',
  totalSources: 'Mittelherkunft - Gesamt',
  refinanceExistingDebt: 'Refinanzierung bestehende Verbindlichkeiten',
  refinanceExistingShareholderLoans: 'Refinanzierung bestehende Gesellschafterverbindlichkeiten',
  netPurchasePrice: 'Netto-Kaufpreis',
  realEstateTransferTax: 'Grunderwerbsteuer',
  notary: 'Notarkosten',
  otherTransactionCosts: 'Sonstige Transaktionskosten',
  capex: 'Capex',
  headsTerms: 'Indikative Finanzierungskonditionen',
  draftTermSheet: '1. Entwurf Termsheet',
  activatedTermSheet: 'Activated Termsheet',
  creditApproval: 'Finale Kreditgenehmigung',
  facilityAgreement: 'Unterzeichnung Kreditvertrag',
  settlementDateValuta: 'Auszahlungsdatum',
  projectDescription: 'Projektbeschreibung',
  rentRoll: 'Mieterliste',
  propertyInformation: 'Grundstückssituation',
  cashFlow: 'Cash Flow & Business Plan Zusammenfassung',
  kycChecklist: 'KYC Checkliste',
  documentsSpeedMatch: 'DOKUMENTE FÜR DAS SPEED MATCH',
  projectTimeline: 'PROJEKTZEITPLAN',

  // banner speed match
  congratulations: 'Glückwunsch!',
  congratulationsMessage: 'Sie befinden sich in einer bilateralen Verhandlung mit <0>LOANLAND</0> <1 /> <2>– dem </2>Anbieter von schnellen und zuverlässigen Immobilienkrediten.',
  submitLoanLand: 'An LOANLAND senden',
  useProvideTemplate: 'Bitte verwenden Sie die beigefügten "Vorlagen".',
  loanLandTemplates: 'LOANLAND Vorlagen',
  loanLandSection: 'LOANLAND Bereich',
  addDocument: 'Dokument hinzufügen',
  saveSuccessLL: 'Die Informationen wurden gespeichert',
  submitSuccessLL: 'Die Informationen wurden übermittelt.',
  saveDraftInMyDeals: 'Save Draft in my deals',
  realStateLoan: 'Immobilienkredit',
  step: 'Schritt',
  startApprovalStep: 'Genehmigungsprozess starten',

  // Approval
  approvalShare: 'TEILEN SIE DEN DEAL ZUR GEHEMIGUNG UND INFORMATION',
  preApprovalSubtitle: 'BENENNEN SIE DIE KONTAKTE FÜR DAS PRE-APPROVAL COMMITTEE',
  approvalSubtitle: 'BENENNEN SIE DIE KONTAKE FÜR DAS CREDIT COMMITTEE',
  addDocuments: 'Dokumente hinzufügen',
  readOnly: 'Read Only',
  approval: 'Approval',
  addNewContact: 'Neuen Kontakt für Approval oder Read-only hinzufügen.',
  otherDocuments: 'Weitere Dokumente',
  termsheet: 'Termsheet',
  creditPaper: 'Credit Paper',
  comments: 'Kommentar',
  giveSomeInformation: 'Weitere Informationen',
  selectPH: 'Bitte auswählen',
  namePH: 'Name',
  decline: 'Ablehnen',
  declineWithFeedback: 'ABLEHNEN MIT FEEDBACK',
  approve: 'GENEHMIGEN',
  approvalStepInformation: 'Approval Step Information',
  status: 'Status',
  approvalContacts: 'Approval Contacts',
  preApprovalCommittee: 'Pre-Approval Committee:',
  approvalCommittee: 'Approval Committee:',
  checkDetails: 'Prüfen Sie bitte die nachstehenden Angaben und geben Sie Feedback.',
  haveNewLoanLand: 'Es gibt einen neuen LOANLAND Deal',
  comment: 'Comment',
  approvalReadOnly: 'hier sind weitere Informationen zu einem neuen LOANLAND Deal (read only).',
  continueTermsheet: 'Weiter zur Termsheet Unterzeichnung',
  reminderWasSent: ' Die Erinnerung wurde gesendet am',
  sorryApprovalWithdrawn: 'Tut uns leid, die Approval Anfrage wurde von LOANLAND zurückgezogen.',
  forFurtherInformation: ' Für weitere Informationen wenden Sie sich bitte an LOANLAND.',
  thankYou: 'Thank you!',
  yourFeedback: 'Ihr Feedback wurde übermittelt.',
  resendEmails: 'Zur Erinnerung wurde eine E-Mail gesendet.',
  bannerTopFeedback: 'LOANLAND hat den internen Pre-Approval-Prozess gestartet und wird sich so schnell wie möglich bei Ihnen melden. Der Deal ist bis auf weiteres inaktiv.',
  ifYouStop: 'Wenn Sie aufhören, gilt dieser Deal als Mismatch.',
  continueNegotiation: 'Ich möchte den Deal fortführen',
  pleaseApproveGiveFeedback: 'Bitte genehmigen oder Feedback geben',
  dataUpdated: 'Einige Dokumente wurden von der Gegenpartei aktualisiert',
  fileDeleted: '{{ file }} Dokument im Feld {{ name }} wurde von der Gegenpartei gelöscht',
  chatHistory: 'Chat Verlauf',
  dealDetails: 'Deal Informationen',
  negotiationHistory: 'Deal-Historie',
  allDoneHere: 'ALLES ERLEDIGT!',
  congratulationsSignedTermsheet: 'GLÜCKWUNSCH ZUM UNTERZEICHNETEN TERMSHEET',
  unfortunatelyMismatch: 'LEIDER IST ES EIN MISMATCH!',
  sorryForNotMaking: 'LEIDER IST ES HIER KEIN MATCH GEWORDEN',
  delete: 'Löschen',
  messages: 'Nachrichten',
  years: 'Jahre',
  yearsUpper: 'Jahre',
  months: 'Monate',

  // Term sheet
  termSheetStep1: 'VON LOANLAND UNTERZEICHNETES TERMSHEET',
  termSheetStep2: 'VON ALLEN UNTERZEICHNETES TERMSHEET',
  pleaseUpload: 'Bitte hochladen',
  uploadedBy: 'hochgeladen von',

  // Chat
  negotiations: 'Deals',
  chat: 'Chat',
  emptyChat: 'Keinen Chat ausgewählt',
  counterparty: 'Gegenpartei',
  startChatting: 'Chat starten',
  newInformation: 'Neue Information',
  you: 'Sie',

  // Transfer
  transferColleague: 'AN TEAMMITGLIED TRANSFERIEREN',
  transferDescription: 'Sie übertragen diesen Deal an ein Teammitglied und haben dann keinen Zugriff mehr hierauf. Ihr Teammitglied kann den Deal an Sie zurückübertragen.',
  shareDeal: 'SHARE THE DEAL',
  shareDescription: 'Teilen Sie den Deal und alle angehängten Dokumente mit anderen (schreibgeschützt). Achten Sie darauf, dass Sie die Dokumente nur an Personen weitergeben, die einer Vertraulichkeitsvereinbarung unterliegen!',
  transferSuccess: 'Deal übertragen',
  transferDealDescription: 'Sie übertragen diese Anfrage an ein Teammitglied und haben dann auf diese sowie auf die zugehörigen Deals keinen Zugriff mehr. Ihr Teammitglied kann die Anfrage an Sie zurückübertragen.',
  addMore: 'ADD MORE',

  hourChanged: 'Ablaufdatum geändert',
  dealDeleted: 'Entwurf gelöscht',
  inputsWithErrors: 'Einige Eingaben sind falsch',

  //Inquiries
  inquiry: 'Anfrage',
  inquiryNumber: 'Anfrage Nummer',
  inquiryName: 'Mein Anfrage Name',
  inquiryBanner: 'hier finden Sie zusätzliche Details zu der Finanzierungsanfrage. Falls Sie weitere Informationen haben möchten oder in direkten Kontakt mit dem Kreditnehmer treten wollen, helfen wir Ihnen gerne weiter unter <1>clientservices@firstwire.market</1> oder +49 221 6777 8850.',
  noAccessTitle: 'es tut uns leid, die Finanzierungsanfrage ist auf dem firstwire Marktplatz nicht mehr aktiv.',
  noAccessContent: 'Bei Fragen oder Anmerkungen kontaktieren Sie uns gerne unter clientservices@firstwire.market oder +49 221 6777 8850.',
  noAccessFooter: '\nViele Grüße,\nIhr firstwire Team',
  noAccessDefault: 'Sie haben auf diese Seite keinen Zugriff'
};

export default resourceDE;
